import Rule from "./game.js";

export function init() {
    const rules = [];
    //nieuwe regels
    rules.push(new Rule(['%s, hoeveel gaybrapaden heeft kasterlee?', 'Als je 1 zei, drink $ keer. Lichtaart heeft er 1, kasterlee is parking. Als je 0 zei, deel $ slokken uit.'], 'gaybrapad', 'brik', `Een regenboogpad, ook wel regenboogzebrapad of gaybrapad, is een gedeelte van de openbare weg dat voorzien is van de kleuren van de regenboogvlag om daarmee aandacht te vragen voor diversiteit en acceptatie van lesbiennes, homoseksuelen, biseksuelen en transgenders (lhbt'ers).`))
    rules.push(new Rule(['%s, Beantwoord dit raadsel juist of anders..: "Wat is de gelijkenis tussen kindjes en medusa?"', '"Ze maken mij allebei steenhard.", Juist? deel $ slokken uit, drink er anders $.'], 'medusa-dark','brik','Dark Humor jokes met dank aan Rinus Depraetere'));
    rules.push(new Rule(['%s, Beantwoord dit raadsel juist of anders..: "Wat is het verschil tussen drugs en %s zijn/haar moeder?"', '"Ik pak geen drugs. (Vooral omdak een algoritme ben)", Juist? deel $ slokken uit, drink er anders $.'], 'drugsellema-dark','brik','Dark Humor jokes met dank aan Rinus Depraetere'));
    rules.push(new Rule(['%s, Beantwoord dit raadsel juist of anders..: "Waarom zeg je je bestelling heel traag als er een vrouw achter den toog staat met een blauw oog?"', '"Ze kan duidelijk niet goed luisteren.", Juist? deel $ slokken uit, drink er anders $.'], 'toogblauwoog-dark','brik','Dark Humor jokes met dank aan Rinus Depraetere'));
    rules.push(new Rule(['%s, Beantwoord dit raadsel juist of anders..: "Wat zegde tege een vrouw me blauw oog en lossen tand?"', '"Gade nei wel luistere?", Juist? deel $ slokken uit, drink er anders $.'], 'medusa-dark','brik','Dark Humor jokes met dank aan Rinus Depraetere'));

    //joink van brikola (met to-be-continued's)
    const joink = []
    joink.push(['Elke keer dat %s scheld mag %s dat woord zichtbaar en leesbaar op zijn lichaam schrijven', 3, 2, 'swear-write-rule', '']);
    joink.push(['%s krijgt niet langer beledigingen opgeschreven van %s.', 3, 2, '', 'swear-write-rule']);
    joink.push(['Tunnet stinkt!', 1, 0, '', '', 'Tunnet stinkt is een deel van een ander zeer doordacht en complex drankspel genaamd triman. In dat spel gooi je om de beurt met 2 dobbelstenen, als je 10 gooit dan roept iedereen: `Tunnet stinkt!`. En dan nemen ze een slok.']);
    joink.push(['Verstoppertje! %s telt tot 15 en zoekt de rest. Wie laatst wordt gevonden mag $ slokken uitdelen', 1, 1, '', '']);
    joink.push(['Vanaf nu moet iedereen die lacht een kip nadoen met zijn armen. 1 slok per keer dat diegene het vergeet', 3, 0, 'chicken-rule', '']);
    joink.push(['Wat is dit voor kippenhok? Gedaan ermee!', 3, 0, '', 'chicken-rule']);
    joink.push(['Zeg om de beurt een willekeurig getal van 1 tot 6. Druk daarna op het scherm. %s begint', 23, 1, 'random-number', '']);
    joink.push(['Het aantal dat je gezegd hebt moet je drinken', 23, 1, '', 'random-number']);
    joink.push(['Doe nu 7 min jouw getal. De uitkomst is het aantal slokken dat je moet drinken', 23, 1, '', 'random-number']);
    joink.push(['%s is de question-queen. Iedereen die antwoord op een vraag van de question-queen drinkt 2 keer', 3, 1, 'question-queen', '']);
    joink.push(['%s is niet langer de question-queen', 3, 1, '', 'question-queen']);
    joink.push(['Vanaf nu is het voor iedereen verboden om de woorden "ja" en "nee" te gebruiken. Wie dit toch zegt wordt bestraft met $ slokken', 3, 0, 'yes-no-forbidden', '']);
    joink.push(['Ja! Ja en nee mogen terug gezegd worden...', 3, 0, '', 'yes-no-forbidden']);
    joink.push(['Vanaf nu moet je de persoon rechts van je over zijn/haar buik wrijven als je moet drinken', 3, 0, 'stomach-rule', '']);
    joink.push(['Je hoeft geen buiken meer aan te raken. Hoera!', 3, 0, '', 'stomach-rule']);
    joink.push(['Iedereen moet een dopje van een pintje op zijn/haar hoofd zetten als een kroon. Drink telkens als het er af valt', 3, 0, 'bottlecap-rule', '']);
    joink.push(['Iedereen mag hun kroon afzetten :(', 3, 0, '', 'bottlecap-rule']);
    joink.push(['Voordat je drinkt moet je %s beledigen', 3, 1, 'rude-drink-rule', '']);
    joink.push(['Stop met beledigen! %s mag 5 slokken uitdelen als wraak', 3, 1, '', 'rude-drink-rule']);
    joink.push(['Echte namen mogen niet meer gebruikt worden! Benoem iedereen met de persoon waarmee die het laatst seks mee gehad heeft', 3, 0, 'ex-name-rule', '']);
    joink.push(['Je kan iedereen terug aanspreken met hun eigen naam', 3, 0, '', 'ex-name-rule']);
    joink.push(['Je hoeft geen shit en bitch meer te zeggen', 3, 0, '', 'shit-bitch-rule']);
    joink.push(['Je moet elke zin beginnen met "shit" en eindigen met "bitch"', 3, 0, 'shit-bitch-rule', '', 'Als je niet mag of niet wilt schelden zeg je chips en Maggie de Blok']);
    joink.push(['Begin elke zin met een hashtag', 3, 0, 'hashtag-rule', '']);
    joink.push(['Je hoeft geen hashtags meer te gebruiken', 3, 0, '', 'hashtag-rule']);
    joink.push(['Beeindig elke zin met .com', 3, 0, 'dotcom-rule', '']);
    joink.push(['Je hoeft geen .com meer te zeggen', 3, 0, '', 'dotcom-rule']);
    joink.push(['Beeindig elke zin met "weet ge wa ik bedoel"', 3, 0, 'knowwhatimean-rule', '']);
    joink.push(['Je hoeft geen "weet ge wa ik bedoel" meer te zeggen', 3, 0, '', 'knowwhatimean-rule']);
    joink.push(['Je mag niet praten als er iemand anders praat. Anders drink je 2 keer', 3, 0, 'troughtalk-rule', '']);
    joink.push(['Je kan terug door elkaar praten', 3, 0, '', 'troughtalk-rule']);
    joink.push(['Je kan niemand meer met zijn echte naam benoemen, iedereen noemt Jef', 3, 0, 'jeff-rule', '']);
    joink.push(['Iedereen heeft hun eigen naam terug', 3, 0, '', 'jeff-rule']);
    joink.push(['Iedereen die een BH draagt drinkt dubbel', 3, 0, 'double-breast-rule', '']);
    joink.push(['BH-dragers moeten niet langer dubbel drinken', 3, 0, '', 'double-breast-rule']);
    joink.push(['%s mag een regel verzinnen', 3, 1, 'manual-rule', '']);
    joink.push(['De regel van %s geldt niet meer langer', 3, 1, '', 'manual-rule']);
    joink.push(['%s is drinkbuddy van.... iedereen! Hij/zij moet meedrinken met iedereen', 3, 1, 'global-drinkbuddy-rule', '']);
    joink.push(['%s is niet meer drinkbuddy van iedereen en mag 6 slokken uitdelen', 3, 1, '', 'global-drinkbuddy-rule']);
    joink.push(['Je mag niet langer in %s\'s ogen kijken. 1 slok per keer dat je faalt', 3, 1, 'eye-rule', '']);
    joink.push(['Je mag terug in %s\'s ogen kijken', 3, 1, '', 'eye-rule']);
    joink.push(['Vanaf nu moet je elke zin beginnen met "Ik zal eens iets zeggen...". Als je het vergeet drink je 1 slok', 3, 0, 'tellwhat-rule', '']);
    joink.push(['Zinnen moeten niet meer worden aangekondigd', 3, 0, '', 'tellwhat-rule']);
    joink.push(['Als je lacht mogen je tanden niet zichtbaar zijn. Gebeurt dit toch, dan drink je $ keer', 3, 0, 'teeth-rule', '']);
    joink.push(['Je kan terug normaal lachen', 3, 0, '', 'teeth-rule']);
    joink.push(['Iedereen praat zoals kesha kesha. Je moet altijd het laatste woord van je zin herhalen herhalen', 3, 0, 'kesha-rule', '']);
    joink.push(['Je hoeft niets meer te herhalen', 3, 0, '', 'kesha-rule']);
    joink.push(['Als je vanaf nu slokken aan iemand uitdeelt, moet je diegene zijn kin aanraken, lief in de ogen kijken en vriendelijk vragen of diegene wilt drinken.', 3, 0, 'romantic-drink-rule', '']);
    joink.push(['Je hoeft niet meer zo klef te doen als je slokken uitdeelt', 3, 0, '', 'romantic-drink-rule']);
    joink.push(['Je mag niet langer "ik" zeggen. In plaats daarvan gebruik je altijd we (bv. we moeten naar het toilet)', 3, 0, 'we-rule', '']);
    joink.push(['Je kan  terug "ik" zeggen', 3, 0, '', 'we-rule']);
    joink.push(['Elke zin die gezegd wordt moet exact 3 woorden bevatten. 1 slok per fout. De Brikola-master heeft deze beperking natuurlijk niet tijdens het Brikoleren', 3, 0, '3-word-sentence-rule', '']);
    joink.push(['Wat is dit voor beperkte woordenschat? Je kan terug meer dan 3 woorden per zin gebruiken', 3, 0, '', '3-word-sentence-rule']);
    joink.push(['%s, slaag %s op zijn/haar bek', 1, 2, '', '']);
    joink.push(['Wie zit er altijd het langst op het toilet? Diegene moet nu $ keer drinken', 1, 0, '', '']);
    joink.push(['Wie zou er met een leerkracht slapen voor hogere punten? Diegene moet nu $ keer drinken', 1, 0, '', '']);
    joink.push(['Wie gaat er trouwen voor het geld? Diegene mag $ slokken uitdelen', 1, 0, '', '']);
    joink.push(['Wie zou er pornoster worden? Diegene mag $ slokken uitdelen', 1, 0, '', '']);
    joink.push(['%s, als er een gebouw in brand staat, wie zou je er dan laatst uithalen? Diegene mag nu $ slokken uitdelen', 1, 1, '', '']);
    joink.push(['Wie zou er als eerst in de gevangenis eindigen? Diegene moet $ keer drinken', 1, 0, '', '']);
    joink.push(['Als je idool een persoonlijk item vlak voor je gooit en een 8-jarig meisje wilt het oprapen, zou je het meisje tegen de grond duwen om het item te pakken? Zoja, drink $ keer.', 1, 0, '', '']);
    let last = [];
    joink.forEach(j => {
        if (last[3] === j[4] && last[3] !== '' || last[4] === j[3] && last[4] !== '') {
            const slides = j[3] === '' ? [last[0], j[0]] : [j[0], last[0]];
            rules.push(new Rule(slides, j[4], "to-be-continued", last[5] || ''));
        } else if (j[3] === '' && j[4] === '') {
            rules.push(new Rule([j[0]], j[3], 'joink1', j[5] || ''));
        }
        last = j;
    });

    const joink2 = [['%s, je hebt 5 minuten om je drinken volledig op te drinken', 1, 1, '', ''],
    ['%s, zeg iets dat je nog NOOIT gedaan hebt. Iedereen dat het heeft gedaan, drink $ keer', 1, 1, '', ''],
    ['%s, deel $ slokken uit', 1, 1, '', ''],
    ['%s drink $ keer', 1, 1, '', ''],
    ['%s, zeg %s\'s verjaardag of drink $ keer', 1, 2, '', ''],
    ['%s, doe een kledingstuk uit of drink $ keer', 1, 1, '', ''],
    ['%s, lik de onderarm van der persoon links van je of drink $ keer', 1, 1, '', ''],
    ['%s, vertel een mop of drink $ keer', 1, 1, '', ''],
    ['%s drink $ keer van %s\'s glas', 1, 2, '', ''],
    ['%s, als je %s kent, geef hem/haar een kus', 1, 2, '', ''],
    ['%s, als je %s kent, lik zijn/haar kaak, of drink $ keer', 1, 2, '', ''],
    ['%s, kus %s of drink $ keer', 1, 2, '', ''],
    ['%s, vraag %s welke vraag dan ook en hij/zei moet antwoorden of de rest van zijn/haar drinken atten', 1, 2, '', ''],
    ['%s, challenge iemand voor een duimworstel-wedstrijd, de verliezer drinkt $ keer', 1, 1, '', ''],
    ['%s en %s hebben een staar-wedstrijd, de verliezer drinkt $ keer', 1, 2, '', ''],
    ['%s, %s moet zoveel slokken drinken als jij zegt', 1, 2, '', ''],
    ['Sta recht! De laatste persoon die rechtstaat drinkt $ keer', 1, 0, '', ''],
    ['%s geef $ slokken aan %s of $ slokken aan %s', 1, 3, '', ''],
    ['%s kiest: of %s deelt $ slokken uit of %s deelt $ slokken uit', 1, 3, '', ''],
    ['%s, drink $ keer en deel $ slokken uit', 1, 1, '', ''],
    ['%s als je een meisje bent mag je $ slokken uitdelen, anders drink je ze zelf!', 1, 1, '', ''],
    ['%s, de volgene persoon die je aankijkt drinkt $ keer', 1, 1, '', ''],
    ['Wie als eerst 2 kledingstukken kan uitdoen mag $ slokken uitdelen', 1, 0, '', ''],
    ['De eerste die zijn elleboog kan likken mag 100 atjes uitdelen', 1, 0, '', ''],
    ['%s en %s moeten overeenkomen om 1 iemand $ slokken te geven', 1, 3, '', ''],
    ['Links handige mensen drinken $ keer', 1, 0, '', ''],
    ['Iedereen met zijn telefoon op tafel drinkt $ keer', 1, 0, '', ''],
    ['De eerste persoon om de jongste persoon aan te wijzen mag $ slokken uitdelen', 1, 0, '', ''],
    ['De persoon met het volste glas drinkt $ keer. Haal in!', 1, 0, '', ''],
    ['De behaarste persoon moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen met de letter "e" in zijn voornaam moet $ keer drinken', 1, 0, '', ''],
    ['%s, toon iedereen de laatste 5 foto\'s in u telefoon of drink $*2 keer', 1, 1, '', ''],
    ['Iedereen met bruin haar moet $ keer drinken', 1, 0, '', ''],
    ['Wie er als laatst seks heeft gehad mag $ slokken uitdelen', 1, 0, '', ''],
    ['De grootste speler mag $ slokken uitdelen', 1, 0, '', ''],
    ['Iedereen die blond is moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen jonger dan %s moet $ keer drinken', 1, 1, '', ''],
    ['Iedereen ouder dan %s moet $ keer drinken', 1, 1, '', ''],
    ['Iedereen groter dan %s moet $ keer drinken', 1, 1, '', ''],
    ['Iedereen kleiner dan %s moet $ keer drinken', 1, 1, '', ''],
    ['Iedereen bruiner dan %s moet $ keer drinken', 1, 1, '', ''],
    ['Iedereen zatter dan %s moet $ keer drinken', 1, 1, '', ''],
    ['Iedereen minder zat dan %s moet $ keer drinken', 1, 1, '', ''],
    ['Iedereen met gaten in zijn sokken moet $ keer drinken. En ge moet ook nieuwe sokken kopen.', 1, 0, '', ''],
    ['%s, geef %s 2 minuten lang toegang tot je Facebook-account of zet een atje', 1, 2, '', ''],
    ['Iedereen die een string draagt moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen met een tattoo of piercing, drink $ keer', 1, 0, '', ''],
    ['Wie de grootste handen heeft tussen %s en %s, drink $ keer', 1, 2, '', ''],
    ['De laatste persoon die naar de badkamer gaat drinkt $ keer', 1, 0, '', ''],
    ['Heeft %s of %s het donkerste ondergoed? Diegene met het donkerste drinkt $ keer. (Als je geen ondergoed draagt moet je ook drinken)', 1, 2, '', ''],
    ['Iedereen met blauwe ogen drinkt $ keer', 1, 0, '', ''],
    ['%s, als je benen niet geschoren zijn drink je $ keer', 1, 1, '', ''],
    ['%s, drink 1 slok zonder je handen of armen te gebruiken', 1, 1, '', ''],
    ['%s vertel een grappig verhaal over een andere speler of drink $ keer', 1, 1, '', ''],
    ['$ slokken voor iedereen!', 1, 0, '', ''],
    ['Sta stil! Persoon die als laatst beweegt drinkt $ keer', 1, 0, '', ''],
    ['De persoon rechts van %s moet $ keer drinken', 1, 1, '', ''],
    ['De persoon links van %s moet $ keer drinken', 1, 1, '', ''],
    ['Handen omhoog! Diegene die laatst zijn handen opsteekt drinkt $ keer', 1, 0, '', ''],
    ['Boomerang boomerang, %s kan zijn drinken weggeven als hij/zei dat wilt', 1, 1, '', ''],
    ['Iedereen dat %s minder dan een maand kent drinkt $ keer', 1, 1, '', ''],
    ['Voetbal fans drinken $ keer', 1, 0, '', ''],
    ['Stop naar je telefoon te kijken! De volgende die zijn telefoon aankijkt drinkt 10 times', 1, 0, '', ''],
    ['Iedereen met een iPhone moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen met een Android telefoon mag $ slokken uitdelen', 1, 0, '', ''],
    ['Iedereen met een bril moet $ keer drinken', 1, 0, '', ''],
    ['Als dit de eerste dag is dat je brikola speelt moet je $ keer drinken', 1, 0, '', ''],
    ['Diegene die laatst weed heeft gerookt moet $ keer drinken', 1, 0, '', ''],
    ['%s drinkt zoveel slokken als die wilt, %s moet er dubbel zoveel drinken', 1, 2, '', ''],
    ['Diegene met de grootste kont moet $ keer drinken', 1, 0, '', ''],
    ['Diegene met het strafste drankje moet $ keer drinken', 1, 0, '', ''],
    ['Als %s goedkopere drank heeft dan %s, moet hij/zei $ keer drinken', 1, 2, '', ''],
    ['Als je verjaardag even is moet je $ keer drinken', 1, 0, '', ''],
    ['Als je verjaardag oneven is moet je $ keer drinken', 1, 0, '', ''],
    ['Iedereen dat %s zijn telefoonnummer niet heeft moet $ keer drinken', 1, 1, '', ''],
    ['%s, wat is de laatste film die je in de cinema hebt gezien? Iedereen dat die film nog niet heeft gezien moet $ keer drinken', 1, 1, '', ''],
    ['%s, kies je drink bitch. Je mag dit spel 2x je slokken uitdelen aan hem/haar', 1, 1, '', ''],
    ['%s, waarheid, durven of atje? %s, mag een vraag of opdracht verzinnen', 1, 2, '', ''],
    ['%s en %s, maak een coole/geheime handdruk. Nu zijn jullie beste vrienden!', 1, 2, '', ''],
    ['Iedereen die met zijn tong zijn neus kan aanraken mag $ slokken uitdelen', 1, 0, '', ''],
    ['Als je single bent mag je $ slokken uitdelen aan een persoon', 1, 0, '', ''],
    ['Iedereen in een relatie mag $ slokken uitdelen aan een persoon', 1, 0, '', ''],
    ['Diegene die als laatst zijn Facebook status updatet moet $ keer drinken', 1, 0, '', ''],
    ['%s, kus %s\'s kont of drink $*2 keer', 1, 2, '', ''],
    ['Diegene die als eerst een echte condoom kan bovenhalen mag $ slokken uitdelen', 1, 0, '', ''],
    ['Lava monster! Diegene die als laatst zijn voeten van de grond haalt drinkt $ keer', 1, 0, '', ''],
    ['%s, noem een accesoire (riem, horloge, ketting etc). Iedereen dat dit accesoire draagt drinkt $ keer', 1, 1, '', ''],
    ['But first, let me take a selfie. %s en %s nemen samen een foto met grappige gezichten', 1, 2, '', ''],
    ['%s, voeg iets toe aan %s\'s drinken', 1, 2, '', ''],
    ['%s, als je ooit een blad hebt moeten gebruiken in plaats van toiletpapier, drink je $ keer', 1, 1, '', ''],
    ['%s, drink zoveel als er spelers zijn', 1, 1, '', ''],
    ['%s, drink zoveel als er tetten zijn (2x per meisje)', 1, 1, '', ''],
    ['Diegene die als laatst heeft overgegeven drinkt $ keer', 1, 0, '', ''],
    ['%s en %s, drink samen $ keer', 1, 2, '', ''],
    ['Iedereen die ooit een SOA had mag $ slokken uitdelen', 1, 0, '', ''],
    ['Vul allemaal je glas bij', 1, 1, '', ''],
    ['De jongste mag $ slokken uitdelen', 1, 0, '', ''],
    ['Diegene met de meeste Facebook vrienden drinkt $ keer', 1, 0, '', ''],
    ['%s, heb je ooit een one-night-stand gehad? Zoja, deel $ slokken uit. Zoniet, drink ze zelf', 1, 1, '', ''],
    ['%s drinkt bij elk nieuw scherm tot zijn naam opnieuw verschijnt', 1, 1, '', ''],
    ['Tikkertje! Als je getikt wordt moet je drinken. Speel dit tot het einde (dat brikola niet heeft)', 1, 1, '', ''],
    ['Iedereen met een pakje sigaretten moet 1x drinken per sigaret (max 8x)', 1, 0, '', ''],
    ['Iedereen dat minder dan 7% alcohol drinkt moet $ slokken drinken', 1, 0, '', ''],
    ['Iedereen die ooit gemasturbeerd heeft op een muziekvideo moet $ keer drinken', 1, 0, '', ''],
    ['%s, toon ons 3 dansmoves of drink $ keer', 1, 1, '', ''],
    ['%s, zeg ons %s\'s leeftijd of drink $ keer', 1, 2, '', ''],
    ['%s, geef ons het sterrenbeeld van %s of drink $ keer', 1, 2, '', ''],
    ['%s, sluit je ogen! Zeg ons nu %s\'s kleur van ogen of drink $ keer', 1, 2, '', ''],
    ['%s, sluit je ogen! Zeg ons nu %s\'s kleur van schoenen of drink $ keer', 1, 2, '', ''],
    ['%s, zeg binnen 3 seconden hoeveel mensen er hier zijn. Als je verliest drink je $ keer. 3....2....1', 1, 1, '', ''],
    ['%s, zeg ons %s\'s favoriete kleur of drink $ keer', 1, 2, '', ''],
    ['Kijk allemaal serieus. Wie als eerst lacht drinkt $ keer', 1, 0, '', ''],
    ['Wie als eerst een telefoon kreeg drinkt $ keer', 1, 0, '', ''],
    ['Wie zijn tenen niet kan raken met gestrekte benen drinkt $ keer', 1, 0, '', ''],
    ['Wie als eerst %s\'s voeten aanraakt mag $ slokken uitdelen', 1, 1, '', ''],
    ['%s, als je 5 minuten kan zwijgen mag je iemand kiezen die zijn drinken atten!', 1, 1, '', ''],
    ['Wie als volgende moet drinken moet dubbel zoveel drinken', 1, 0, '', ''],
    ['Wie als eerste "JA" zegt mag iemand kiezen die zijn drinken moet leegdrinken!', 1, 0, '', ''],
    ['Wie als laatst gedouched heeft moet $ keer drinken', 1, 0, '', ''],
    ['%s, lach 1 minuut lang. $ slokken als het mislukt.', 1, 1, '', ''],
    ['Als je dit leest, drink $ keer', 1, 0, '', ''],
    ['Als je iemand deze zin hoort vertellen, drink $ keer', 1, 0, '', ''],
    ['%s, doe een tap-dance van 5 seconden of drink $ keer', 1, 1, '', ''],
    ['%s heeft 3 minuten brikola immuniteit (je mag je lever 3 minuten laten rusten door niet te drinken)', 1, 1, '', ''],
    ['De minst behaarde persoon drinkt $ keer!', 1, 0, '', ''],
    ['Rangschik de 3 behaardste spelers: 3e plaats drinkt 1x, 2e plaats drinkt 2x en eerste plaats drinkt 3x!', 1, 0, '', ''],
    ['Rangschik de 3 grootste spelers: 3e plaats drinkt 1x, 2e plaats drinkt 2x en eerste plaats drinkt 3x!', 1, 0, '', ''],
    ['Rangschik de 3 slimste spelers: 3e plaats drinkt 1x, 2e plaats drinkt 2x en eerste plaats drinkt 3x!', 1, 0, '', ''],
    ['Rangschik de 3 donkerste spelers: 3e plaats drinkt 1x, 2e plaats drinkt 2x en eerste plaats drinkt 3x!', 1, 0, '', ''],
    ['Rangschik de 3 meest vrijgevige spelers: 3e plaats drinkt 1x, 2e plaats drinkt 2x en eerste plaats drinkt 3x!', 1, 0, '', ''],
    ['Rangschik de 3 zwerverigste spelers: 3e plaats drinkt 1x, 2e plaats drinkt 2x en eerste plaats drinkt 3x!', 1, 0, '', ''],
    ['De 3 amigos %s, %s en %s, jullie drinken allemaal $ keer!', 1, 3, '', ''],
    ['Wie als eerste een inspirerend gezegde verzint (voor een waardeloze poster) mag $ slokken uitdelen!', 1, 0, '', ''],
    ['Rangschik de 3 jongste spelers: jongste drinkt 1x, tweede jongste drinkt 2x en derde jongste drinkt 3x!', 1, 0, '', ''],
    ['%s, als je ooit gestreakt hebt mag je $ slokken uitdelen. Zoniet moet je $x drinken.', 1, 1, '', ''],
    ['Wie vandaag geen douche heeft genomen drinkt $ keer', 1, 0, '', ''],
    ['%s, als je flip flops draagt mag je $ slokken uitdelen. Zoniet moet je $ keer drinken.', 1, 1, '', ''],
    ['Iedereen die in een sport team van een school heeft gezeten mag $ slokken uitdelen.', 1, 0, '', ''],
    ['%s, als je gekrulde haren hebt mag je $ slokken uitdelen. Zoniet moet je $ keer drinken.', 1, 1, '', ''],
    ['De host van deze plaats mag $ slokken uitdelen. Als er geen host is mag %s de slokken uitdelen!', 1, 1, '', ''],
    ['%s, imiteer een andere speler. Wie als eerst kan raden wie het is mag $ slokken uitdelen!', 1, 1, '', ''],
    ['%s, doe iets wat %s zegt. Als je het weigert of niet kan moet je $ keer drinken.', 1, 2, '', ''],
    ['Iedereen die ooit op TV is geweest moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen die ooit stiekem de berichtjes van zijn lief zijn/haar telefoon heeft nagekeken moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen die ooit heeft gespiekt op een examen drinkt $ keer!', 1, 0, '', ''],
    ['Iedereen die ooit zat een status of foto op Facebook heeft gepost drinkt $ keer', 1, 0, '', ''],
    ['%s, als je ooit je liefde hebt erkend aan iemand via een dronken SMS mag je $ slokken uitdelen. Zoniet drink je $ keer.', 1, 1, '', ''],
    ['%s, als je ooit geslapen hebt in de klas of op je werk mag je $ slokken uitdelen. Zoniet drink je $ keer.', 1, 1, '', ''],
    ['%s, als je ooit iets hebt gestolen mag je $ slokken uitdelen. Zoniet drink je $ keer.', 1, 1, '', ''],
    ['%s, als je je warm voelt door de drank mag je $ slokken uitdelen. Zoniet drink je $ keer!', 1, 1, '', ''],
    ['%s, als je ooit bent buitengegooid in een cafe of op een fuif mag je $ slokken uitdelen. Zoniet drink je $ keer.', 1, 1, '', ''],
    ['%s, als je ooit je relatie hebt stopgezet via sociale media (SMS, messenger, ...) mag je $ slokken uitdelen. Zoniet drink je $ keer.', 1, 1, '', ''],
    ['%s, als je ooit gelogen hebt over je leeftijd om iets te krijgen mag je $ slokken uitdelen. Zoniet drink je $ keer.', 1, 1, '', ''],
    ['%s, als je ooit iemand hebt geslagen terwijl je zat was mag je $ slokken uitdelen. Zoniet drink je $ keer.', 1, 1, '', ''],
    ['Iedereen die nooit een strip tease heeft gedaan moet $ keer drinken. Misschien wordt vannacht de nacht!', 1, 0, '', ''],
    ['%s, laat %s een snapchat versturen vanaf jouw telefoon of drink $ keer!', 1, 2, '', ''],
    ['Iedereen die ooit een gescheurde condoom heeft gebruikt moet $ keer drinken!', 1, 0, '', ''],
    ['Als je ooit $ avonden op rij zat was moet je nu $ keer drinken!', 1, 0, '', ''],
    ['Als je ooit voor 10u sochtend bent gestart met drinken moet je nu $ keer drinken!', 1, 0, '', ''],
    ['Iedereen die ooit 3 dagen op rij geen ondergoed aanhad moet nu $ keer drinken!', 1, 0, '', ''],
    ['Rangschik de 3 spelers met de minste gezichtsbeharing: gladste babyface drinkt 1 keer, tweede gladste drinkt 2 keer en derd gladste drinkt 3 keer!', 1, 0, '', ''],
    ['Atheisten drinken $ keer!', 1, 0, '', ''],
    ['Iedereen die in geesten gelooft drinkt $ keer', 1, 0, '', ''],
    ['Iedereen die gisteren zat was drinkt $ keer!', 1, 0, '', ''],
    ['Iedereen die de laatste avond uit moest overgeven drinkt nu $ keer!', 1, 0, '', ''],
    ['%s, als je denkt schattiger te zijn dan %s moet je $ keer drinken.', 1, 2, '', ''],
    ['%s, als je ooit gearresteerd bent moet je $ keer drinken.', 1, 1, '', ''],
    ['%s, als je ooit een gevecht hebt begonnen moet je $ keer drinken', 1, 1, '', ''],
    ['%s, als je ooit hebt deelgenomen aan een zat bekgevecht moet je $ keer drinken! ', 1, 1, '', ''],
    ['%s, als je vanavond minder dan 2 glazen hebt gedronken drink je nu $ slokken!', 1, 1, '', ''],
    ['Diegene die bij de vorige regel hebben moeten drinken, drinken nu nogmaals $ slokken.', 1, 0, '', ''],
    ['Diegene die bij de vorige regel hebben moeten drinken, mogen $ slokken uitdelen.', 1, 0, '', ''],
    ['%s, als je al meer dan $ glazen ophebt vanavond mag je $ slokken uitdelen.', 1, 1, '', ''],
    ['Als je handel/bedrijfsbeheer (hebt) studeert moet je $ keer drinken!', 1, 0, '', ''],
    ['Als je eender welke soort engeneering (hebt) studeert moet je $ keer drinken!', 1, 0, '', ''],
    ['Als je marketing (hebt) studeert moet je $ keer drinken!', 1, 0, '', ''],
    ['Als je latijn (hebt) studeert moet je $ keer drinken!', 1, 0, '', ''],
    ['Als je beroeps (hebt) studeert moet je $ keer drinken!', 1, 0, '', ''],
    ['Als je wetenschappen (hebt) studeert moet je $ keer drinken!', 1, 0, '', ''],
    ['Iedereen wie vandaag op Facebook heeft gezeten moet $ keer drinken!', 1, 0, '', ''],
    ['Iedereen wie vandaag nog geen slokken heeft uitgedeelt mag er nu $ uitdelen!', 1, 0, '', ''],
    ['Heeft %s of %s het minst gedronken? Diegene die het minst heeft gedronken moet $ keer drinken.', 1, 2, '', ''],
    ['Wie als eerste een familielid kan SMSen mag $ slokken uitdelen!', 1, 0, '', ''],
    ['Wie als volgende moet drinken mag $ slokken uitdelen', 1, 0, '', ''],
    ['Iedereen die ooit zijn/haar haar heeft gekleurd moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen die studeert voor of werkt in een handarbeid werkgedoe moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen die ooit zijn/haar nagels heeft gelakt moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen die zijn kleren vandaag voor het eerst draagt moet $ keer drinken', 1, 0, '', ''],
    ['Drink $ keer als je ooit je Facebook profiel hebt aangepast om het er beter uit te laten zien', 1, 0, '', ''],
    ['Wie hier als laatste is bijgekomen moet $ keer drinken', 1, 0, '', ''],
    ['Drink $ keer als je ooit een andere speler zijn Facebook account hebt gehackt', 1, 0, '', ''],
    ['Drink $ keer als je Facebook account ooit is gehackt', 1, 0, '', ''],
    ['Drink $ keer als je ooit MSN hebt gehad', 1, 0, '', ''],
    ['Iedereen met een even leeftijd moet een foto sturen naar iemand van eigen keuze of $ keer drinken', 1, 0, '', ''],
    ['Rechtshandigen drinken $ keer', 1, 0, '', ''],
    ['Iedereen die zich ziek voelt drinkt $ keer', 1, 0, '', ''],
    ['Drink $ keer als je ooit slow hebt gedanst zonder erna te daten of te kussen', 1, 0, '', ''],
    ['Drink $ keer als je ooit iemand hebt gedatet na diegene een briefje gegeven te hebben in het middelbaar', 1, 0, '', ''],
    ['Drink $ keer als je vriend/vriendin ooit is afgepakt door een van je vrienden', 1, 0, '', ''],
    ['Drink $ keer als je ooit hebt gekakt op een Turks toilet', 1, 0, '', ''],
    ['Iedereen die vandaag gedanst heeft moet $ keer drinken', 1, 0, '', ''],
    ['Wie als laatst zijn telefoon heeft gechecked (behalve voor brikola) moet $ keer drinken', 1, 0, '', ''],
    ['Alle rokers moeten $ keer drinken', 1, 0, '', ''],
    ['Als je de maker van Pikalo persoonlijk kent moet je $ keer drinken', 1, 0, '', ''],
    ['Drink $ keer als je ooit geworsteld hebt op een tatami (Japanse judo mat)', 1, 0, '', ''],
    ['%s moet 6 squats doen en bij elke squat 1x drinken!', 1, 1, '', ''],
    ['%s ga langs iedereen en tik iemand 2x op zijn rug aan. Diegene moet 2x drinken!', 1, 1, '', ''],
    ['Iedereen die harder gestudeerd heeft dan %s moet $ keer drinken', 1, 1, '', ''],
    ['If you can\'t join your hands behind your back, one from the top and one from the bottom, drink $ times', 1, 0, '', ''],
    ['De persoon links van de persoon die dit leest moet $ keer drinken', 1, 0, '', ''],
    ['De persoon rechts van de persoon die dit leest moet $ keer drinken', 1, 0, '', ''],
    ['De persoon links van de persoon die dit leest mag $ slokken uitdelen', 1, 0, '', ''],
    ['De persoon rechts van de persoon die dit leest mag $ slokken uitdelen', 1, 0, '', ''],
    ['Iedereen moet zoveel slokken drinken als jaren dat die gestudeerd heeft na het middelbaar', 1, 0, '', ''],
    ['Iedereen met de letter "a" in zijn voornaam moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen met de letter "f" in zijn voornaam moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen met de letter "s" in zijn voornaam moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen met de letter "o" in zijn voornaam moet $ keer drinken', 1, 0, '', ''],
    ['Alle minderjarigen drinken $ keer', 1, 0, '', ''],
    ['Drink $ keer als je ooit bent blijven zitten', 1, 0, '', ''],
    ['%s mag zoveel slokken uitdelen als die broers of zussen heeft', 1, 1, '', ''],
    ['%s moet zoveel drinken als het aantal landen dat die bezocht heeft', 1, 1, '', ''],
    ['Iedereen met een huisdier moet $ keer drinken', 1, 0, '', ''],
    ['Diegene met de dunste armen moet $ keer drinken! Misschien dat je dan naar de fitness gaat... ', 1, 0, '', ''],
    ['Iedereen met een baard moet $ keer drinken', 1, 0, '', ''],
    ['Bekennings tijd. Iedereen die ooit in de zee heeft gekakt moet $ keer drinken', 1, 0, '', ''],
    ['Alle vuile mensen die ooit op straat hebben gekakt moeten $ keer drinken!', 1, 0, '', ''],
    ['%s als je leeftijd even is moet je $ keer drinken, $ als het oneven is!', 1, 1, '', ''],
    ['Wie is de mopperigste persoon? Die moet $ keer drinken', 1, 0, '', ''],
    ['%s moet in zijn/haar kont geknepen worden door %s of die moet $ slokken drinken', 1, 2, '', ''],
    ['Als je niet vanaf het begin meedeed met brikola moet je nu $ slokken drinken. Shame on you!', 1, 0, '', ''],
    ['Wie als eerste met het idee kwam om brikola te spelen mag $ slokken uitdelen', 1, 0, '', ''],
    ['Alle meisjes moeten $ keer drinken', 1, 0, '', ''],
    ['Alle jongens moeten $ keer drinken', 1, 0, '', ''],
    ['Diegene die als laatst een waterpark heeft bezocht mag $ slokken uitdelen!', 1, 0, '', ''],
    ['Iedereen die in 3 of meer verschillende dorpen heeft gewoond moet $ keer drinken', 1, 0, '', ''],
    ['%s, geef een sterkte van iedereen en drink per keer!', 1, 1, '', ''],
    ['%s, geef een zwakte van iedereen en drink per keer!', 1, 1, '', ''],
    ['Iedereen die ooit heeft gedroomt dat die aan het vechten was moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen die ooit is wakker geworden terwijl die aan het praten was in zijn slaap moet $ keer drinken', 1, 0, '', ''],
    ['%s, zet 3 atjes of deel $ slokken uit', 1, 1, '', ''],
    ['%s moet $ slokken drinken terwijl %s iets in zijn/haar oor fluistert', 1, 2, '', ''],
    ['Iedereen die groter is dan %s moet het aantal centimeter dat die groter is drinken', 1, 1, '', ''],
    ['%s, nodig iemand van u vrienden uit naar hier of drink $ keer', 1, 1, '', ''],
    ['Wie aait graag katten? Drink $ keer', 1, 0, '', ''],
    ['Iedereen die niet de volledige bovenkant van zijn glas in zijn mond krijgt moet $ keer drinken', 1, 0, '', ''],
    ['Alle trotse eigenaars van een unibrow moeten $ keer drinken', 1, 0, '', ''],
    ['Diegene die net op het scherm tikte moet $ keer drinken', 1, 0, '', ''],
    ['%s, imiteer een slang voor 5 seconden of drink $ keer', 1, 1, '', ''],
    ['%s, imiteer een naaktslak voor 5 seconden of drink $ keer', 1, 1, '', ''],
    ['%s, imiteer een schildpad voor 5 seconden of drink $ keer', 1, 1, '', ''],
    ['%s maak een grappig gezicht terwijl %s een foto van je maakt en drink daarna $ keer', 1, 2, '', ''],
    ['Drink $ keer als je meer dan 100km van deze plaats bent geboren', 1, 0, '', ''],
    ['Deel $ slokken uit als je binnen de 25km van deze plaats bent geboren', 1, 0, '', ''],
    ['Als je ooit hebt gezegd "laten we gewoon vrienden blijven", drink $ keer!', 1, 0, '', ''],
    ['Drink $ keer als je thuis de deur openlaat als je naar de badkamer gaat', 1, 0, '', ''],
    ['Drink $ keer als je vandaag een orgasme hebt gehad', 1, 0, '', ''],
    ['Drink $ keer als je ooit de foute richting hebt gereden in een eenrichtingsstraat', 1, 0, '', ''],
    ['Drink $ keer als je geslapen hebt met iemand met foute bedoelingen', 1, 0, '', ''],
    ['Drink $ keer als je de smaak van je partner\'s genetalien lekker vindt', 1, 0, '', ''],
    ['Drink $ keer als je ooit 5 dagen na elkaar niet hebt gedouched', 1, 0, '', ''],
    ['Drink $ keer als er ooit iemand in een ziekenhuis is beland door jou', 1, 0, '', ''],
    ['Drink $ keer als je ooit de grootte van je penis (of die van u partner) met die van een vriend hebt vergeleken', 1, 0, '', ''],
    ['Drink $ keer als je stiekem verliefd bent op iemand van u vrienden', 1, 0, '', ''],
    ['Drink $ keer als je denkt de rest van je leven samen te blijven met je huidige partner', 1, 0, '', ''],
    ['Drink $ keer als je ooit gelogen hebt tegen iemand hier zodat je niet naar zijn/haar feestje moest', 1, 0, '', ''],
    ['Iedereen wie niet gestemt heeft met de laatste verkiezingen mag $ slokken uitdelen!', 1, 0, '', ''],
    ['Drink $ keer als je altijd je handen wast nadat je naar de badkamer bent geweest, drink $ keer als je ooit hebt gedaan alsof', 1, 0, '', ''],
    ['Drink $ keer als je ooit kandidaat bent geweest van een TV show', 1, 0, '', ''],
    ['Drink $ keer als je ooit iets gestolen hebt van een andere speler', 1, 0, '', ''],
    ['Drink $ keer als je ooit in het zwart hebt gewerkt', 1, 0, '', ''],
    ['Drink $ keer als je ooit geld hebt "geleend" van je partner zonder het te zeggen', 1, 0, '', ''],
    ['Drink $ keer als je ooit gelogen hebt over de grootte van je penis (of die van je partner)', 1, 0, '', ''],
    ['Drink $ keer als je ooit bent tegengehouden door de politie', 1, 0, '', ''],
    ['Drink $ keer als je ooit in hectenis bent genomen', 1, 0, '', ''],
    ['Drink $ keer als je ooit je partners ondergoed hebt geprobeerd', 1, 0, '', ''],
    ['Drink $ keer als je een meisje bent en je je partner\'s broek of t-shirt als pyjama hebt gebruikt', 1, 0, '', ''],
    ['Drink $ keer als je met iemand zou neuken voor 10.000 euro, als diegene in goede gezondheid verkeert', 1, 0, '', ''],
    ['Drink $ keer als je deze week erotische dromen hebt gehad', 1, 0, '', ''],
    ['Drink $ keer als je ooit een andere speler naakt hebt gezien', 1, 0, '', ''],
    ['Drink $ keer als je ooit hebt geneukt op je werk', 1, 0, '', ''],
    ['Drink $ keer als je ooit op jezelf zat bent geworden', 1, 0, '', ''],
    ['Drink $ keer als je slecht slaapt na seks', 1, 0, '', ''],
    ['Drink $ keer als de laatste film die je zag romantisch was', 1, 0, '', ''],
    ['Drink $ keer als de laatste film die je zag Amerikaans was', 1, 0, '', ''],
    ['Drink $ keer als je ooit je penis (of die van je partner) hebt gemeten', 1, 0, '', ''],
    ['Drink $ keer als je ooit in iemands bed bent wakker geworden en geen idee had hoe je daar kwam', 1, 0, '', ''],
    ['Drink $ keer als je ooit seks hebt gehad in water', 1, 0, '', ''],
    ['Drink $ keer als je na elk telefoongesprek met je partner "Ik hou van u" zegt', 1, 0, '', ''],
    ['Alle klootzakken die "mag ik een hap" hebben gevraagd wanneer iemand eten bovenhaalde moeten $ keer drinken', 1, 0, '', ''],
    ['Drink $ keer als je ooit naar iets gezocht hebt dat in je broekzak zat', 1, 0, '', ''],
    ['Drink $ keer als je ooit geprobeerd hebt om alle 4 de kleuren van een 4-kleuren pen tesamen in te drukken', 1, 0, '', ''],
    ['Dag domerikken! Drink $ keer als je ooit geprobeerd hebt een washandje te vullen met water', 1, 0, '', ''],
    ['Lang leve de domerikken! Drink $ keer als je ooit je telefoon boven je hoofd hebt gehouden omdat je geen ontvangst had', 1, 0, '', ''],
    ['De speler die met het ergste meisje ooit heeft gedate moet $ keer drinken', 1, 0, '', ''],
    ['De speler die met de ergste jongen ooit heeft gedate moet $ keer drinken', 1, 0, '', ''],
    ['Drink $ keer als je ooit iemand hebt ontmaagd', 1, 0, '', ''],
    ['Meisjes, drink je BH-grootte als slokken (A = 1, B = 2, etc.)', 1, 0, '', ''],
    ['Drink $ keer als je ooit hebt geneukt met iemand van de lagere school terwijl je in het middelbaar zat', 1, 0, '', ''],
    ['Drink $ keer als je denkt dat leggings ook broeken zijn', 1, 0, '', ''],
    ['%s, deel zoveel slokken uit als je wilt aan %s', 1, 2, '', ''],
    ['%s, drink zoveel keer als het aantal seks-partners van je', 1, 1, '', ''],
    ['%s, geef een durven-opdracht aan %s, als hij/zei het uitvoert mag diegene $ slokken uitdelen', 1, 2, '', ''],
    ['Drink $ keer als je ooit bent blijven zitten in school', 1, 0, '', ''],
    ['Slimerikken die een schooljaar hebben overgeslagen moeten $ keer drinken', 1, 0, '', ''],
    ['Sharks who study or work in finance or accounting drink $ times', 1, 0, '', ''],
    ['Nerds die studeren of werken ivm computerwetenschappen drinken $ keer', 1, 0, '', ''],
    ['Geldmakers die studeren of werken in verkoop moeten $ keer drinken', 1, 0, '', ''],
    ['Iedereen die begint kaal te worden moet $ keer drinken', 1, 0, '', ''],
    ['Drink $ keer als je je "koffie - sigaret - toiletbezoek ritueel" minimum elke 2 dagen doet', 1, 0, '', ''],
    ['Drink $ keer als je een jager bent', 1, 0, '', ''],
    ['Drink $ keer als je ooit meerdere fuckbuddies had op hetzelfde moment', 1, 0, '', ''],
    ['Drink $ keer als iemand je ooit bedrogen heeft', 1, 0, '', ''],
    ['%s stuur een stoute SMS naar een persoon gekozen door %s, of drink $*2 keer', 1, 2, '', ''],
    ['%s stuur "Ik hou van u" via SMS naar een persoon gekozen door %s, of drink $*2 keer', 1, 2, '', ''],
    ['%s moet 10 vriendenverzoeken sturen via Facebook of $ keer drinken', 1, 1, '', ''],
    ['%s, stuur iemand gekozen door %s een vriendenverzoek op Facebook of drink $ keer', 1, 2, '', ''],
    ['%s, publiceer een foto op Facebook genomen door %s of drink $*2 keer', 1, 2, '', ''],
    ['Als de klok nu een oneven aantal geeft, moet iedereen $ keer drinken. Anders mag %s $ slokken uitdelen', 1, 1, '', ''],
    ['Drink $ keer als je schoenen veters hebben', 1, 0, '', ''],
    ['Het meisje met de grootste tetten mag $ slokken uitdelen', 1, 0, '', ''],
    ['De jongen met het minste lichaamshaar moet $ keer drinken', 1, 0, '', ''],
    ['De persoon met de grootste neus moet $ keer drinken', 1, 0, '', ''],
    ['Deel $ slokken uit als je schoenmaat groter is dan 45', 1, 0, '', ''],
    ['Deel $ slokken uit als je schoenmaat kleiner is dan 40', 1, 0, '', ''],
    ['Drink $ keer als je schoenmaat tussen 40 en 45 is', 1, 0, '', ''],
    ['Deel $ slokken uit als je witte sokken draagt', 1, 0, '', ''],
    ['Deel $ slokken uit als je nooit een Star Wars film hebt gezien', 1, 0, '', ''],
    ['Deel $ slokken uit als je nooit een Lord of The Rings film hebt gezien', 1, 0, '', ''],
    ['Deel $ slokken uit als je nooit een Harry Potter film hebt gezien', 1, 0, '', ''],
    ['Diegene die als eerst "Shit ik heb mijn kat in de ruimte achtergelaten" kan roepen wanneer de klok een veelvoud van 10 toont (8:00, 8:10, ...) mag 10 slokken uitdelen', 1, 0, '', ''],
    ['Vanaf nu is het verboden een sigaret aan te steken. Zet een atje als je denkt dat je boven de wet staat', 1, 0, '', ''],
    ['Drink $ keer als je ooit meer dan 1.000 euro hebt uitgegeven op 1 dag', 1, 0, '', ''],
    ['Drink $ keer als je denkt dat je het beste hebt geneukt met je huidige partner! Of je kan alsof doen, niemand zal het verschil weten...', 1, 0, '', ''],
    ['Drink $ keer als je denkt dat je het beste hebt geneukt met een ex.', 1, 0, '', ''],
    ['Waar zijn die nerds! Drink $ keer als je ooit meer dan 4u aan een stuk een videospel hebt gespeeld', 1, 0, '', ''],
    ['Klootzakken die geld hebben gestolen van een kennis drinken $ keer', 1, 0, '', ''],
    ['Drink $ keer als je recent hebt gedroomt over iets wat je niet eens aan je beste vriend of vriendin kan vertellen...', 1, 0, '', ''],
    ['Drink $ keer als, zoals een zwerver, je ooit je halte gemist hebt omdat je in slaap was gevallen', 1, 0, '', ''],
    ['Wie vandaag als eerst zijn glas had gevuld mag $ slokken uitdelen', 1, 0, '', ''],
    ['Wie als laatst zijn glas heeft bijgevuld moet $ keer drinken', 1, 0, '', ''],
    ['Drink $ keer als je een Windows phone hebt', 1, 0, '', ''],
    ['Iedereen die meer dan 10 keer brikola heeft gespeeld viert feest! $ slokken mijn vrienden', 1, 0, '', ''],
    ['Drink $ keer als je afgelopen week een relatie drama had', 1, 0, '', ''],
    ['Deel $ slokken uit als je op je nagels bijt', 1, 0, '', ''],
    ['Alle rugby en voetbalspelers drinken $ keer!', 1, 0, '', ''],
    ['Voetbalspelers drinken $ keer!', 1, 0, '', ''],
    ['Basketbal spelers drinken $ keer!', 1, 0, '', ''],
    ['Handbal spelers drinken $ keer!', 1, 0, '', ''],
    ['Idioten die al zat met de auto hebben gereden, drink $ keer. Alle rest, verstop hun autosleutels...', 1, 0, '', ''],
    ['Deel $ slokken uit als je al meer dan een jaar aan een stuk een relatie hebt gehad', 1, 0, '', ''],
    ['Kies iemand die zijn drinken leegdrinkt als je momenteel in een relatie zit van langer dan 3 jaar', 1, 0, '', ''],
    ['Drink $ keer als je liever een kat dan een hond hebt', 1, 0, '', ''],
    ['Deel $ slokken uit als je ooit al buiten Europa bent geweest', 1, 0, '', ''],
    ['Deel $ slokken uit als je ooit een huisdier had buiten een kat of hond', 1, 0, '', ''],
    ['Deel $ slokken uit als je al meer dan 3 mensen hebt gedumpt', 1, 0, '', ''],
    ['Mensen jonger dan 20 drinken $ keer!', 1, 0, '', ''],
    ['Mensen ouder dan 22 drinken $ keer!', 1, 0, '', ''],
    ['Gitaarspelers drinken $ keer', 1, 0, '', ''],
    ['Iedereen met een groot hart die al eens geld heeft gedoneerd aan een goed doel mag $ slokken uitdelen', 1, 0, '', ''],
    ['Drink $ keer als je ooit een beugel had', 1, 0, '', ''],
    ['Drink $ keer als je graag kleine hoedjes draagt', 1, 0, '', ''],
    ['Drink $ keer als je huidige outfit meer dan 250 euro gekost heeft', 1, 0, '', ''],
    ['Deel $ slokken uit als je ooit in het buitenland hebt geleefd', 1, 0, '', ''],
    ['Deel $ slokken uit als je auto cruisecontrol heeft', 1, 0, '', ''],
    ['Drink $ keer als je meer dan 400 euro aan je telefoon hebt uitgegeven', 1, 0, '', ''],
    ['Diegenen die voor doodstraf zijn drinken $ keer', 1, 0, '', ''],
    ['Alle fitnessers drinken $ keer', 1, 0, '', ''],
    ['Drink $ keer als je ooit geplast hebt zonder je handen te gebruiken', 1, 0, '', ''],
    ['De eerste persoon die een driehoekig object kan opnoemen dat in de buurt is mag $ slokken uitdelen!', 1, 0, '', ''],
    ['Iedereen die schrik heeft van spinnen drinkt $ keer!', 1, 0, '', ''],
    ['Als je ooit je partner hebt benoemd met je ex-partner\'s naam mag je $ slokken uitdelen', 1, 0, '', ''],
    ['Iedereen die ooit is aangevallen door een dier drinkt $ keer', 1, 0, '', ''],
    ['Drink $ keer als je ooit aan iemand gevraagd hebt om je ondergoed te dragen', 1, 0, '', ''],
    ['Deel $ slokken uit als je ooit een fucking hete jongen of meisje hebt afgewezen', 1, 0, '', ''],
    ['Avengers fans (of soortgelijke rommel) drinken $ slokken', 1, 0, '', ''],
    ['Wie als laatst zijn verjaardag viert mag $ slokken uitdelen', 1, 0, '', ''],
    ['Drink $ keer als je je partner ooit hebt gechanteerd', 1, 0, '', ''],
    ['Drink $ keer als je kater voorbereidingen hebt genomen (emmer, fles water, ...)', 1, 0, '', ''],
    ['Drink $ keer als je meer dan 2 weekends op rij uitgaat', 1, 0, '', ''],
    ['Drink $ keer als je water/lemon juice/Alka-Seltzer hebt klaargezet voor morgen "ochtend"', 1, 0, '', ''],
    ['Deel $ slokken uit als je morgen moet werken (en stiekem gaat huilen)', 1, 0, '', ''],
    ['%s moet het volgende liedje kiezen', 1, 1, '', ''],
    ['%s moet het volgende liedje kiezen, diegene die het goed vinden drinken $ keer', 1, 1, '', ''],
    ['%s moet het volgende liedje kiezen, diegene die het slecht vinden drinken $ keer', 1, 1, '', ''],
    ['Drink $ keer als je een politieke voorkeur hebt', 1, 0, '', ''],
    ['Drink $ keer als je ooit iemand hebt gekust na een slow dans', 1, 0, '', ''],
    ['Deel $ slokken uit als er ooit een krantenartikel over je is geschreven', 1, 0, '', ''],
    ['Piano spelers die weten hoe ze met 10 vingers spelen mogen $ slokken uitdelen', 1, 0, '', ''],
    ['Drink $ keer als je ooit op een paard hebt gereden', 1, 0, '', ''],
    ['Drink $ keer als je alcohol drinkt dat je (nog) niet hebt gekocht', 1, 0, '', ''],
    ['Deel $ slokken uit als je alcohol drinkt dat je gekocht hebt', 1, 0, '', ''],
    ['%s kiest iemand om mee te slowen, hand op de kont. $ slokken als hij/zei afwijst', 1, 1, '', ''],
    ['Iedereen die gekust heeft met iemand in een nachtclub mag $ slokken uitdelen', 1, 0, '', ''],
    ['Drink $ keer als je er niet tegen bent om te betalen voor seks', 1, 0, '', ''],
    ['Drink $ keer als je nooit bloed hebt gedoneerd (klootzak)', 1, 0, '', ''],
    ['Drink $ keer als je nooit een South Park aflevering hebt gezien', 1, 0, '', ''],
    ['Drink $ keer als je meer dan 3 diplomas hebt', 1, 0, '', ''],
    ['Meisjes die lippenstift dragen drinken $ keer', 1, 0, '', ''],
    ['Drink $ keer als je niet je echte naam op Facebook gebruikt', 1, 0, '', ''],
    ['%s, geef de namen van 3 exen van %s of drink $ keer', 1, 2, '', ''],
    ['Meisjes drinken zoveel slokken als er knappe jongens meespelen', 1, 0, '', ''],
    ['Jongens drinken zoveel slokken als er knappe meisjes meespelen', 1, 0, '', ''],
    ['Iedereen drinkt evenveel slokken als het aantal neukbare mensen die meespelen', 1, 0, '', '', 'Neukbaar = iemand die je zou doen, ik ben maar een programma maar zelfs ik weet dat er wel een paar slokken aan de orde zijn (;'],
    ['%s geef het inwonersaantal van Tajikistan (+ or - 1,000,000) of drink $ keer', 1, 1, '', ''],
    ['Deel $ slokken uit als je ooit een autoaccident hebt meegemaakt', 1, 0, '', ''],
    ['Deel $ slokken uit als je ooit een operatie hebt gehad', 1, 0, '', ''],
    ['Spelers die alleen wonen drinken $ keer, weeral alleen...', 1, 0, '', ''],
    ['De 3 jongste spelers kiezen samen iemand die $ slokken drinkt', 1, 4, '', ''],
    ['De meisjes kiezen samen een jongen die $ slokken drinkt', 1, 0, '', ''],
    ['Wijnliefhebbers drinken $ slokken', 1, 0, '', ''],
    ['Drink $ keer als je regelmatig naar Familie kijkt', 1, 0, '', ''],
    ['Iedereen met whiskey is een noob, drink $ keer', 1, 0, '', ''],
    ['Drink $ keer als je witte sokken draagt', 1, 0, '', ''],
    ['Drink $ keer als je ooit ontslagen bent', 1, 0, '', ''],
    ['Iedereen die tegen vegetariers is drinkt $ keer', 1, 0, '', ''],
    ['Drink $ keer als je geen brikola op je telefoon hebt', 1, 0, '', ''],
    ['Iedereen die een getrimde baard verkiest boven een harige, drinkt $ keer', 1, 0, '', ''],
    ['De vriendelijke persoon die dit spelletje van brikola host mag $ slokken uitdelen', 1, 0, '', ''],
    ['Drink $ keer als je ooit een move hebt gedaan op een partner van een vriend', 1, 0, '', ''],
    ['Drink $ keer als je ooit een LIVE striptease hebt gezien', 1, 0, '', ''],
    ['Drink $ keer als je ooit een politiecontrole probeerde te ontwijken', 1, 0, '', ''],
    ['Drink $ keer als je ooit iemand van hetzelfde geslacht op de mond hebt gekust', 1, 0, '', ''],
    ['Drink $ keer als je ooit iemand van hetzelfde geslacht hebt getongzoend', 1, 0, '', ''],
    ['Drink $ keer als je ooit een handtekening vervalst hebt', 1, 0, '', ''],
    ['Drink $ keer als je aan je grote teen kan zuigen', 1, 0, '', ''],
    ['Drink $ keer als je ooit een excuus verzonnen hebt om niet naar werk te moeten gaan', 1, 0, '', ''],
    ['Drink $ keer als je afgelopen 48u hebt geneukt', 1, 0, '', ''],
    ['Drink $ keer als je denkt dat een speler totaal niet grappig is', 1, 0, '', ''],
    ['Drink $ keer als je denkt dat een speler veel dommer is dan de rest', 1, 0, '', ''],
    ['Drink $ keer als je je stiefmoeder haat', 1, 0, '', ''],
    ['Drink $ keer als je agressief dronken bent', 1, 0, '', ''],
    ['Deel $ slokken uit als je afgelopen 7 dagen meer dan 2 keer hebt geneukt', 1, 0, '', ''],
    ['Drink $ keer als een van je vrienden je ex is', 1, 0, '', ''],
    ['%s, down 3 drinks in a row or give brikola a 5 star mark in the app store :)', 1, 1, '', ''],
    ['Drink $ keer als je denkt dat ALLE spelers cool zijn', 1, 0, '', ''],
    ['Drink $ keer als je ooit seks hebt gehad in het bed van je ouders', 1, 0, '', ''],
    ['Drink $ keer als je denkt dat je partner\'s genetalien er raar uitzien', 1, 0, '', ''],
    ['Deel $ slokken uit als je meer dan 4 keer bent gedumpt', 1, 0, '', ''],
    ['Drink $ keer als je soms aan je vuile kleren riekt om te beslissen of je ze nog een dag draagt', 1, 0, '', ''],
    ['Drink $ keer als je ooit met meerdere verschillende mensen van dezelfde familie hebt geslapen', 1, 0, '', ''],
    ['Drink $ keer als je partner vaker geil is dan jou', 1, 0, '', ''],
    ['Drink $ keer als je jezelf deze week naakt in de spiegel hebt gezien', 1, 0, '', ''],
    ['Drink $ keer als je ooit het feit dat je gemasturbeerd hebt hebt verstopt van je partner', 1, 0, '', ''],
    ['Drink $ keer als je spijt hebt van iemand te hebben geneukt', 1, 0, '', ''],
    ['Drink $ keer als je ooit iemand in zijn gezicht hebt gezegd dat die lelijk is', 1, 0, '', ''],
    ['Als er regelmatig misbruik wordt gemaakt van je bankrekening mag je $ slokken uitdelen', 1, 0, '', ''],
    ['Als je ooit een scheet hebt gelaten tijdens de seks mag je $ slokken uitdelen', 1, 0, '', ''],
    ['Als je ooit een scheet hebt gelaten terwijl je iemand kuste mag je $ slokken uitdelen', 1, 0, '', ''],
    ['Drink $ keer als je afgelopen 3 dagen gemasturbeerd hebt', 1, 0, '', ''],
    ['Iedereen die ooit op zijn/haar werk heeft gemasturbeerd mag $ slokken uitdelen', 1, 0, '', ''],
    ['Als je ooit seks hebt gehad terwijl er iemand anders in dezelfde ruimte lag te slapen mag je $ slokken uitdelen', 1, 0, '', ''],
    ['Drink $ keer als je ooit een aansteker hebt gebruikt om je scheet in brand te steken', 1, 0, '', ''],
    ['Drink $ keer als je denkt dat sommige mensen hier een heel marginale naam hebben', 1, 0, '', ''],
    ['%s, deel $ slokken uit aan de persoon die hier het slimst is', 1, 1, '', ''],
    ['%s, deel $ slokken uit aan de persoon die hier het mooist is', 1, 1, '', ''],
    ['%s, deel $ slokken uit aan de persoon die hier het heetste is', 1, 1, '', ''],
    ['%s, als je ontmaagd bent na je 16e, drink $ keer', 1, 1, '', ''],
    ['Those who don\'t have liked the brikola Facebook page drink $ times, unless you put a like within the next minute.. ;)', 1, 0, '', ''],
    ['%s, drink $ times if you haven\'t marked brikola with 5 stars in the app store... Unless you do it right away ;)', 1, 1, '', ''],
    ['Drink $ keer als je tweede teen langer is dan je grote teen', 1, 0, '', ''],
    ['Drink $ keer als je ooit meer dan 3 reality TV afleveringen in een rij hebt gezien', 1, 0, '', ''],
    ['Drink $ keer als je ooit een kind hebt verteld dat Sinterklaas, de tandenfee of de Kerstman niet bestaan', 1, 0, '', ''],
    ['%s en %s - steem, papier, schaar. De verliezer drinkt $ keer', 1, 2, '', ''],
    ['Beslis de wie pussy van vandaag is, hij/zei moet $ keer drinken', 1, 0, '', ''],
    ['Iedereen beslist wie het grootst is, Hulk krijgt $ slokken', 1, 0, 'solidman', ''],
    ['Chuck Norris gaat niet akkoord, Hulk drinkt nog $ slokken', 1, 0, '', 'solidman'],
    ['Hulk mag wraak nemen en $ slokken uitdelen', 1, 0, '', 'solidman'],
    ['%s en %s zijn drinkbuddies. Proost!', 2, 3, 'married', ''],
    ['Vriendschap is voorbij! %s en %s, jullie zijn geen drinkbuddies meer', 2, 2, '', 'married'],
    ['%s, %s en %s, jullie vormen een groep. Als een van jullie drinkt, moet de rest volgen', 2, 4, 'group-3', ''],
    ['%s, %s en %s, we verbreken de groep. Jullie kunnen terug individueel drinken', 2, 3, '', 'group-3'],
    ['Wees vriendelijk tegen %s, die vanaf nu iedereen zal bedienen', 2, 1, 'waiter', ''],
    ['%s heeft genoeg gedaan. Hij of zij moet niet meer serveren', 2, 1, '', 'waiter'],
    ['%s, vanaf nu ben je stil. Niet meer praten dus, maar mimen zou nice zijn', 2, 1, 'king-of-silence', ''],
    ['%s, je kan terug spreken', 2, 1, '', 'king-of-silence'],
    ['%s, je moet vanaf nu je hand opsteken om te praten en %s zal je toestemming geven', 2, 2, 'raise-hand-to-speak', ''],
    ['%s hoeft niet langer toestemming te vragen aan %s om te spreken', 2, 2, '', 'raise-hand-to-speak'],
    ['%s de papegaai. Je moet alles herhalen wat %s zegt', 2, 2, 'parrot', ''],
    ['%s, stop met %s te herhalen, dat is enorm irritant', 2, 2, '', 'parrot'],
    ['%s, geef je telefoon aan %s tot je hem mag teruggeven', 2, 2, 'give-phone', ''],
    ['%s, je mag je telefoon terugnemen', 2, 1, '', 'give-phone'],
    ['%s moet nu met een Hollands accent spreken', 2, 1, 'chinese-accent', ''],
    ['%s, je kan terug normaal spreken', 2, 1, '', 'chinese-accent'],
    ['%s stop met dat Hollands accent!', 2, 1, '', 'chinese-accent'],
    ['%s, kijk naar %s tot je mag stoppen', 2, 2, 'stare', ''],
    ['%s, je kan stoppen met %s aan te staren', 2, 2, '', 'stare'],
    ['%s, start met %s te masseren', 2, 2, 'massage', ''],
    ['%s, je kan stoppen met %s te masseren', 2, 2, '', 'massage'],
    ['%s, je mag stoppen met %s te masseren en hij/zij moet $ keer drinken', 2, 2, '', 'massage'],
    ['%s we kunnen jou steeds vragen stellen. Drink telkens als je niet het juiste antwoord weet.', 2, 1, 'questions', ''],
    ['%s mag stoppen met onze belachelijke vragen te beantwoorden', 2, 1, '', 'questions'],
    ['%s, kies een woord dat jij moet zeggen als iemand drinkt', 2, 1, 'must-tell-drink', ''],
    ['%s, je moet niet langer je magische woord zeggen als iemand drinkt', 2, 1, '', 'must-tell-drink'],
    ['%s, je bent verboden van je eigen glas te drinken. Je moet telkens van iemand anders zijn/haar glas drinken', 2, 1, 'other-drink', ''],
    ['%s, je kan terug van je eigen glas drinken', 2, 1, '', 'other-drink'],
    ['Elke keer dat %s in zijn/haar handen klapt, moet iedereen drinken ', 2, 1, 'crazy-claper', ''],
    ['%s is niet meer de klap-koning', 2, 1, '', 'crazy-claper'],
    ['%s, je moet een dierengeluid maken elke keer als je drinkt', 2, 1, 'animal-noise', ''],
    ['%s, je mag stoppen met die rare geluiden', 2, 1, '', 'animal-noise'],
    ['%s, ga op %s\'s schoot zitten', 2, 2, 'seat-knees', ''],
    ['%s ga van %s\'s schoot (als je wilt)', 2, 2, '', 'seat-knees'],
    ['%s en %s, wissel van drinken', 2, 2, 'switch-glass', ''],
    ['%s en %s, neem je eigen drinken terug', 2, 2, '', 'switch-glass'],
    ['Het is vanaf nu verboden voor %s om persoonlijke voornaamwoorden (ik, jij, hij, ...) te gebruiken met als risico van $ keer drinken', 2, 1, 'pronoun', ''],
    ['%s kan terug met persoonlijke voornaamwoorden spreken!', 2, 1, '', 'pronoun'],
    ['%s, je bent de sprekende klok! Maak elke minuut de tijd duidelijk. Drink 1 keer per minuut die je vergeet aan te kondigen.', 2, 1, 'timewatch', ''],
    ['%s, je kan stoppen met de tijd aan te kondigen!', 2, 1, '', 'timewatch'],
    ['%s mag vanaf nu geen klinkers meer uitspreken!', 2, 1, 'consonant', ''],
    ['%s kan terug normaal praten!', 2, 1, '', 'consonant'],
    ['%s is blind in een oog! Sluit een oog tot nader order!', 2, 1, 'one-eyed', ''],
    ['%s, je kan terug met 2 ogen kijken!', 2, 1, '', 'one-eyed'],
    ['%s moet 2 seconden pauzeren tussen elk gesproken woord! Drink 1 keer per vergeten keer.', 2, 1, 'wait-2secs', ''],
    ['%s kan terug normaal praten!', 2, 1, '', 'wait-2secs'],
    ['%s is een kletskous! Vanaf nu moet je steeds aan het praten zijn!', 2, 1, 'talkative', ''],
    ['%s, je kan terug stil zijn.', 2, 1, '', 'talkative'],
    ['%s mag vanaf nu enkel zingend spreken!', 2, 1, 'sing123', ''],
    ['%s kan terug normaal praten!', 2, 1, '', 'sing123'],
    ['Elke keer dat %s praat, moet %s 1 keer drinken, dit is ook omgekeerd zo.', 2, 2, 'mouthfuls-mirror', ''],
    ['%s en %s kunnen terug spreken zonder elkaar te laten drinken', 2, 2, '', 'mouthfuls-mirror'],
    ['We zijn allemaal klootzakken! Je mag %s vanaf nu alleen nog maar beledigen en hij/zij mag niets terugzeggen.', 2, 1, 'sub-shit', ''],
    ['Iedereen, stop met %s te beledigen en drink $ keer als straf voor jullie ongeschikte gedrag.', 2, 1, '', 'sub-shit'],
    ['%s en %s, wissel van t-shirt tot nader order!', 2, 2, 'swap-tshirt', ''],
    ['%s en %s, jullie mogen jullie eigen shirt terug.', 2, 2, '', 'swap-tshirt'],
    ['%s is de wreker! Elke keer dat iemand slokken uitdeelt mag de wreker beslissen of de gever ook moet drinken!', 2, 1, 'vigilante', ''],
    ['%s is niet meer de wreker!', 2, 1, '', 'vigilante'],
    ['%s, je hebt het Tourettes syndroom. Als je een woord roept moet iedereen het terugroepen. Wie het als laatst terugroept moet $ keer drinken.', 2, 1, 'tourette-man', ''],
    ['%s, je Tourettes syndroom is behandeld. Gefeliciteerd!', 2, 1, '', 'tourette-man'],
    ['%s is boos! Elke keer dat hij/zei (glim)lacht moet die $ slokken drinken.', 2, 1, 'upset-man', ''],
    ['%s is niet meer boos!', 2, 1, '', 'upset-man'],
    ['%s is bipolair! Je moet steeds enorm blijf of droevig zijn tot nader order!', 2, 1, 'bipolar', ''],
    ['%s is niet meer bipolair!', 2, 1, '', 'bipolar'],
    ['Back to the future voor %s! Je moet in de toekomstige tijd spreken tot nader order. Elke foute vervoeging is $ slokken!', 2, 1, 'speak-future', ''],
    ['%s kan terug in tegenwoordige tijd spreken.', 2, 1, '', 'speak-future'],
    ['%s moet met een Jamaicaans accent praten! Jahweeel man...', 2, 1, 'african-accent', ''],
    ['%s kan terug normaal spreken', 2, 1, '', 'african-accent'],
    ['%s moet met een Frans accent praten! Denk aan Elio Di Rupo...', 2, 1, 'english-accent', ''],
    ['%s kan terug normaal spreken', 2, 1, '', 'english-accent'],
    ['%s moet "natuurlijk" zeggen na elke zin dat iemand zegt. Drink $ keer als je het vergeet.', 2, 1, 'thats-obvious', ''],
    ['%s, je kan stoppen met "natuurlijk" te zeggen.', 2, 1, '', 'thats-obvious'],
    ['%s, je moet de gebaren van %s imiteren!', 2, 2, 'imitate-gesture', ''],
    ['%s, stop met %s te imiteren!', 2, 2, '', 'imitate-gesture'],
    ['%s en %s zijn bondgenoten! Ze mogen alle slokken verdelen of uitwisselen die voor elkaar bedoeld zijn', 2, 2, 'ally', ''],
    ['%s en %s zijn geen bondgenoten meer!', 2, 2, '', 'ally'],
    ['%s moet negatief antwoorden met een argument op elke vraag dat hem/haar gesteld wordt!', 2, 1, 'answer-no', ''],
    ['%s mag terug normaal op vragen antwoorden', 2, 1, '', 'answer-no'],
    ['%s, ga op je knieën... we zullen wel zeggen wanneer je terug recht mag staan!', 2, 1, 'on-your-knees', ''],
    ['%s kan terug rechtstaan!', 2, 1, '', 'on-your-knees'],
    ['%s moet op één been staan of op een bil zitten tot nader order!', 2, 1, 'on-one-leg', ''],
    ['%s kan terug een comfortabele positie aannemen', 2, 1, '', 'on-one-leg'],
    ['%s moet wisselen tussen staan en zitten met elke nieuwe regel! Hij of zij moet $ keer drinken als die het vergeet', 2, 1, 'switch-up-n-down', ''],
    ['%s mag terug eender welke positie aannemen!', 2, 1, '', 'switch-up-n-down'],
    ['%s, draai je om en toon je rug aan iedereen. We zullen je wel melden wanneer we je gezicht terug willen zien', 2, 1, 'back-to-everyone', ''],
    ['%s mag zich terug omdraaien!', 2, 1, '', 'back-to-everyone'],
    ['%s, dans in het rond en stop niet, of drink voor elke regel dat je niet aan het dansen bent!', 2, 1, 'dance-now', ''],
    ['%s mag stoppen met dansen', 2, 1, '', 'dance-now'],
    ['%s moet vanaf nu constant wandelen, je krijgt een $-slokken straf voor elke keer dat je stopt!', 2, 1, 'dont-stop-walking', ''],
    ['%s, je marathon is afgelopen', 2, 1, '', 'dont-stop-walking'],
    ['%s you\'re the penguin! You\'re not able to use your fingers', 2, 1, 'without-fingers', ''],
    ['%s congratulations, you just learned how to use your fingers again', 2, 1, '', 'without-fingers'],
    ['%s, you\'re under arrest! Keep your hands behind your head except for drinking', 2, 1, 'keep-hands-on-your-head', ''],
    ['%s can leave his or head alone', 2, 1, '', 'keep-hands-on-your-head'],
    ['%s IST DEUTSCH ! You must speak with your best german accent', 2, 1, 'sprich-deutsch', ''],
    ['%s can have his or her sexy native accent again!', 2, 1, '', 'sprich-deutsch'],
    ['%s you\'re so shy! You must not answer a question or you get a $-sip penalty', 2, 1, 'shy-man', ''],
    ['%s can answer questions again', 2, 1, '', 'shy-man'],
    ['%s is the paparazzi! Each time you take a picture of someone without noticing, he or she must drink $ times', 2, 1, 'paparazzi', ''],
    ['%s isn\'t the paparazzi anymore', 2, 1, '', 'paparazzi'],
    ['%s, you have bad manners! From now on you must speak with your mouth full or you get a $-sip penalty', 2, 1, 'mouth-full', ''],
    ['%s can speak normally again!', 2, 1, '', 'mouth-full'],
    ['%s is the impolite one! Keep your elbows on the table until told otherwise!', 2, 1, 'impolite', ''],
    ['%s has improved his/her manners, you can take your elbows off of the table!', 2, 1, '', 'impolite'],
    ['%s likes hugs tonight! Each time you touch the neck of someone, they have to drink $ times', 2, 1, 'hug-way-of-life', ''],
    ['%s doesn\'t like hugs anymore', 2, 1, '', 'hug-way-of-life'],
    ['%s is the dancing queen. Dance whenever and however you want, the last person to imitate must drink $ times', 2, 1, 'dancing-queen', ''],
    ['%s is not the dancing queen anymore!', 2, 1, '', 'dancing-queen'],
    ['%s is the team captain! You can put your hand on the table whenever you want, the last player to put their hand on the pile has to drink $ times', 2, 1, 'team-captain-hand', ''],
    ['%s, you\'re not captain anymore!', 2, 1, '', 'team-captain-hand'],
    ['%s, we know you love your booze. Each time you drink you must toast with someone who will be following the amount of sips you take', 2, 2, 'cheers-man', ''],
    ['%s can take a small break from the booze', 2, 2, '', 'cheers-man'],
    ['%s you are the referee of the Wild West! Choose 2 players. The first to be "shot" by the other has to drink $ times', 2, 3, 'western-referee', ''],
    ['%s no longer rules the Wild West. ', 2, 3, '', 'western-referee'],
    ['%s is Batman! Speak like him or drink $ times each time you speak', 2, 1, 'batman', ''],
    ['%s isn\'t Batman anymore', 2, 1, '', 'batman'],
    ['%s, you must imitate %s in his or her gesture until you\'re told otherwise!', 2, 2, 'act-like-someone', ''],
    ['%s, stop imitating %s', 2, 2, '', 'act-like-someone'],
    ['%s, you\'re David Guetta of the poor! Choose any song anytime', 2, 1, 'david-guetta-man', ''],
    ['%s you\'re not the DJ of the night', 2, 1, '', 'david-guetta-man'],
    ['%s you\'re antisocial! You only speak to %s because he or she is your only friend', 2, 2, 'not-sociable', ''],
    ['%s you\'ve been socialized!', 2, 2, '', 'not-sociable'],
    ['%s you\'re the eye of the snake! Everytime you meet someone\'s gaze, he or she drink $ times', 2, 1, 'snake-man', ''],
    ['%s you\'re not the eye of the snake anymore', 2, 1, '', 'snake-man'],
    ['%s, you such a suck up! Everytime you speak, compliment %s, or drink $ times', 2, 2, 'compliment-man', ''],
    ['%s, you don\'t have to suck up to %s anymore', 2, 2, '', 'compliment-man'],
    ['%s, you\'re the magician! Swap player drinks anytime, they\'ll deal with it', 2, 1, 'magician', ''],
    ['%s, you\'re not the magician anymore', 2, 1, '', 'magician'],
    ['It is now forbidden to say the word "drink" under penalty of drinking!', 3, 0, 'drink-verb-forbidden', ''],
    ['You can now say the word "drink" again', 3, 0, '', 'drink-verb-forbidden'],
    ['You must now insult the anyone that you are talking to', 3, 0, 'insult', ''],
    ['Stop insulting each other you assholes!', 3, 0, '', 'insult'],
    ['You must now use at least one curse word in every sentence', 3, 0, 'bad-word', ''],
    ['You can stop saying so many f*cking curse words', 3, 0, '', 'bad-word'],
    ['You are now banned from drinking with your right hand', 3, 0, 'no-right-hand', ''],
    ['You can now use your right hand again, for drinking', 3, 0, '', 'no-right-hand'],
    ['Each person now gets the first name of the person to their right, whoever gets it wrong has to drink', 3, 0, 'right-firstname', ''],
    ['You can have your own name back now', 3, 0, '', 'right-firstname'],
    ['You must now say how you are punctuating sentences (ex "I am really hammered period")', 3, 0, 'ponctuation', ''],
    ['After this sentence, everyone can stop saying how they are punctuating sentences exclamation mark', 3, 0, '', 'ponctuation'],
    ['You must end every sentence with "I love you"', 3, 0, 'love-you', ''],
    ['You can stop ending sentences with "I love you"', 3, 0, '', 'love-you'],
    ['Girls, you are now in charge of serving drinks', 3, 0, 'all-waitress', ''],
    ['Girls, stop being waitresses', 3, 0, '', 'all-waitress'],
    ['If you say either of these words "yes" or "no", you have to drink $ times until we move on to a new rule', 3, 0, 'no-yes', ''],
    ['You can now say "yes" or "no" again. Congratulations', 3, 0, '', 'no-yes'],
    ['It is now forbidden to point your finger at another player', 3, 0, 'point-finger', ''],
    ['Point your fingers as much as you want', 3, 0, '', 'point-finger'],
    ['%s the Thumb King! At any time, you can put your thumb on the table. The last person to copy you has to drink!', 3, 1, 'thumb-king', ''],
    ['%s is no longer the Thumb King', 3, 1, '', 'thumb-king'],
    ['Every waste of alcohol is punishable by drinking $ times. (Peeing is waste)', 3, 0, 'alcool-waste', ''],
    ['No more punishment for wasting alcohol. You can now pour shitty beer down the sink and pee in peace.', 3, 0, '', 'alcool-waste'],
    ['Everyone has to address %s as "Master"', 3, 1, 'master-name', ''],
    ['%s is no longer the "Master"', 3, 1, '', 'master-name'],
    ['%s invent a new rule. Get creative', 3, 1, 'rule-before-drink', ''],
    ['%s\'s rule doesn\'t apply anymore', 3, 1, '', 'rule-before-drink'],
    ['No more addressing people by their first names!', 3, 0, 'no-firstname', ''],
    ['You can now use players\' first names!', 3, 0, '', 'no-firstname'],
    ['Ban on asking questions under penalty of drinking $ times', 3, 0, 'no-question', ''],
    ['You can ask questions again', 3, 0, '', 'no-question'],
    ['Everyone must ignore %s. No talking or looking at him/her', 3, 1, 'ignore', ''],
    ['Everyone can stop ignoring %s', 3, 1, '', 'ignore'],
    ['%s, choose a word that is now banned. Anyone who says the word has to drink.', 3, 1, 'no-choose-word', ''],
    ['The word chosen by %s is no longer banned', 3, 1, '', 'no-choose-word'],
    ['If you have to drink an odd number of times, you now drink double. (eg. 1, 3, 5 drinks are now 2, 6, 10 drinks)', 3, 0, 'odd-doubled', ''],
    ['The odd number of drinks are no longer double', 3, 0, '', 'odd-doubled'],
    ['Insult the person to your right every time you drink', 3, 0, 'insult-right', ''],
    ['You can stop being rude to the poor person on your right', 3, 0, '', 'insult-right'],
    ['You can no longer look people in the eye when speaking to them', 3, 0, 'neighbors-eyes', ''],
    ['You can stop awkwardly staring at the ground when speaking to people', 3, 0, '', 'neighbors-eyes'],
    ['Everytime you speak to someone, end your sentence by giving them a High Five', 3, 0, 'phrase-high-five', ''],
    ['High Fives are no longer obligatory', 3, 0, '', 'phrase-high-five'],
    ['Everytime you take a drink, you have to grab on to the person to your right', 3, 0, 'grab-right', ''],
    ['You can stop groping the person to your right everytime you drink', 3, 0, '', 'grab-right'],
    ['New rule! Before every drink, you must take the imaginary straw out of your glass and hold it in one hand. Don\'t forget to shake out the straw to prevent imaginary spilling!', 3, 0, 'straw', ''],
    ['You can stop this ridiculous straw rule.', 3, 0, '', 'straw'],
    ['New rule! Each time someone talks he/she must end the sentence with "%s, you suck!"', 3, 1, 'fuck-you', ''],
    ['%s doesn\'t suck anymore.. And he/she can give out $ drinks as punishment for everyone\'s rudeness.', 3, 1, '', 'fuck-you'],
    ['%s is freezeman. Each time you stop moving (discretely), everyone must copy your frozen movement. The last person to do so, drinks $ times!', 3, 1, 'freezer', ''],
    ['%s, you aren\'t the freezeman anymore.', 3, 1, '', 'freezer'],
    ['Sniper game! The first sniper is %s. He /she must wink at someone who will then drink twice and becomes the sniper. Continue the game until further notice.', 3, 1, 'sniper', ''],
    ['Sniper game over! The last sniper drinks $ times.', 3, 0, '', 'sniper'],
    ['You can no longer hold your drink with your writing hand. Drink $ times for every mistake.', 3, 0, 'buffalo', ''],
    ['Everyone can hold their drinks however they want again!', 3, 0, '', 'buffalo'],
    ['New rule everyone! Speak like Yoda you shall, otherwise drink $ times, you will.', 3, 0, 'yoda', ''],
    ['Speaking not like Yoda, you can. (You don\'t have to speak like Yoda anymore, that sentence is weird.)', 3, 0, '', 'yoda'],
    ['New rule! Everyone must choose a hand gesture. When you want to speak to someone, you must start by addressing them with their chosen gesture.', 3, 0, 'sign', ''],
    ['Stop flipping people off! (you can stop with the hand gesture rule)', 3, 0, '', 'sign'],
    ['You can now play heads or tails everytime before drinking. If you win, you don\'t drink. If you lose, you drink double', 3, 0, 'heads-tails-rule', ''],
    ['You can\'t play heads or tails before drinking anymore!', 3, 0, '', 'heads-tails-rule'],
    ['Everyone to the library! We are all whispering or take $ sips each time we get too loud', 3, 0, 'library-time', ''],
    ['We can stop whispering', 3, 0, '', 'library-time'],
    ['New rule: no profanity! Each time a person says a bad word he or she must drink $ times', 3, 0, 'no-slang', ''],
    ['We can speak f*cking freely again you f*cking motherf*ckers', 3, 0, '', 'no-slang'],
    ['Everyone put down your glass and pick up the glass to the right of you', 3, 0, 'give-up-glass-to-right', ''],
    ['Take your glass back!', 3, 0, '', 'give-up-glass-to-right'],
    ['New rule, express yourself in slow motion!', 3, 0, 'slow-motion', ''],
    ['You can speak normally again', 3, 0, '', 'slow-motion'],
    ['New rule, we have to ask a question in order to speak to someone! $ sips each time we don\'t', 3, 0, 'only-questions', ''],
    ['You can now speak to someone without asking a question', 3, 0, '', 'only-questions'],
    ['From now on, you all have a lisp!', 3, 0, 'everyone-lisps', ''],
    ['No more lisps! ...unless you really have one. ', 3, 0, '', 'everyone-lisps'],
    ['From now on, as soon as a song stops you have to spin around, the last player to do this, drink $ times', 3, 0, 'spin-each-song', ''],
    ['Hey guys, stop spinning around', 3, 0, '', 'spin-each-song'],
    ['Choose your punching bag! Every sip intended for you will go to your punching bag instead (sips are transferred only once)', 3, 0, 'scapegoat', ''],
    ['No more punching bags!', 3, 0, '', 'scapegoat'],
    ['From now on, it\'s totally forbidden to touch your hair or face! $ sips for each touch', 3, 0, 'dont-touch-head', ''],
    ['You can touch yourself again!', 3, 0, '', 'dont-touch-head'],
    ['YOU SHALL NOT ... TOUCH THE TABLE. Or take a $-sip penalty', 3, 0, 'dont-touch-table', ''],
    ['You can touch the table again', 3, 0, '', 'dont-touch-table'],
    ['New rule: everytime someone gets an object from another player, he or she takes $ sips as well', 3, 0, 'no-receiving', ''],
    ['You can now freely receive objects from the other players again', 3, 0, '', 'no-receiving'],
    ['What rhymes with "inx"? First person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Names of Ben & Jerry\'s ice cream flavors", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Things you can do to avoid a hangover", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Adjectives which apply to %s", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 2, '', ''],
    ['Synonyms for the word "penis", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['Synonyms for the word "vagina", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['Synonyms for the term "get drunk", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['Synonyms for the word "prostitute", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['Synonyms for the word "sex", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['Synonyms for the word "anus", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['Synonyms for the word "mastur*ate", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"%s ex\'s", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 2, '', ''],
    ['"Things that %s loves above all", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 2, '', ''],
    ['"Things that %s can\'t stand", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 2, '', ''],
    ['"The criteria for a sexual partner for %s", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 2, '', ''],
    ['"Famous handicapped people", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Really shitty films", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Brands of whiskey", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"The dwarves from Snow White", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Brittney Spears song titles", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Simpsons characters", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"The odd numbers between 324 and 332", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Old English snooty insults", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Biggest turn offs", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"South Park characters", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"The erotic areas on a woman", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['What rythmes with "itch", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Pokémons", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Dragon Ball Z characters", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Lord Of The Rings characters", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"The fingers of the hand", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['Synonyms for the word "tits", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Harry Potter characters", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Names of Harry Potter films", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Super Mario characters", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Star Wars characters", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Something red and white", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Farm animals", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Sex positions", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"McDonalds meals", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"The colors of the Power Rangers", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Disney movies", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Really hot actresses", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, 'sexy-actress', ''],
    ['If you said Megan Fox, give out 10 sips', 4, 0, '', 'sexy-actress'],
    ['If you said Scarlett Johansson, drink 10 times', 4, 0, '', 'sexy-actress'],
    ['If you said Natalie Portman or Jessica Alba, give out $ sips', 4, 0, '', 'sexy-actress'],
    ['%s, start a story and each player must continue it. At "The End", everyone drinks!', 4, 1, '', ''],
    ['"Breeds of dogs", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"African countries", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Famous dogs (cartoons count)", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Foreign currencies", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Famous dictators", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Words beginning with the letter Z", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Famous criminals", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Things you find in a girl\'s purse", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['Names of towns beginning with a letter that %s chooses', 4, 1, '', ''],
    ['"Company advertisement slogans", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Blue objects in the room", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Sauces other than ketchup and mayo", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Social networks", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Things that %s and %s have in common", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 3, '', ''],
    ['"Drugs", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Socially unacceptable ways to catch someone\'s attention", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Worst gift you have ever received", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['Characters of the Belgian comic "Tintin", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Bones in your body", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"TV hosts", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Characters of Desperate Housewives", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Radio stations in this area", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Bars/clubs in this area", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Super hot female singers", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Super hot male singers", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Songs by David Guetta", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"What does %s have more than %s", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 3, '', ''],
    ['"Benefits of being a man", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Benefits of being a woman", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Advantages of being drunk", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Damaging effects of getting drunk", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"What does %s have less than %s", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 3, '', ''],
    ['"Names of cocktails", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Characters of the Lion King", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Characters of the movie the Avengers", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"KFC sandwiches", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Song names by Michael Jackson", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Zodiac signs", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"NFL teams", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"NBA teams", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Evil villian from a comic or movie", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Names of songs by the Beatles", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Names of songs by Beyonce", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Names of songs by Queen", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Names of songs by Elton John", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['Go around the room and list your most annoying moments, the first one to run out of things to say, drink $ times. %s starts!', 4, 1, '', ''],
    ['Go around the room and list TV series, the first to run out of answers drink $ times. %s starts!', 4, 1, '', ''],
    ['Go around the room and list tv talk shows, the first to run out of answers drink $ times. %s starts!', 4, 1, '', ''],
    ['What rhymes with "ate"? The first one to run out of words drink drink $ times, %s you start', 4, 1, '', ''],
    ['What rhymes with "ute"? The first one to run out of words drink $ times, %s you start', 4, 1, '', ''],
    ['What rhymes with "oin"? The first one to run out of words drink $ times, %s you start', 4, 1, '', ''],
    ['What rhymes with "ache"? The first one to run out of words drink $ times, %s you start', 4, 1, '', ''],
    ['What rhymes with "ob"? The first one to run out of words drink $ times, %s you start', 4, 1, '', ''],
    ['Go around the room and name the smurfs, first person to fail drink $ times. %s starts!', 4, 1, '', ''],
    ['Go around the room and list common sayings, first person to fail drink $ times. %s starts!', 4, 1, '', ''],
    ['Go around the room and list the parts of a computer, the first to run out of items drink $ times. %s starts!', 4, 1, '', ''],
    ['Go around the room and list characters who have a sword, the first to run out of names drink $ times. %s starts!', 4, 1, '', ''],
    ['Go around the room, list the Super Bowl teams, the first to run out of team names drink $ times. %s starts!', 4, 1, '', ''],
    ['Go around the room, do a rude gesture. The one to repeat or who lacks inspiration must drink $ times. %s you start!', 4, 1, '', ''],
    ['Go around the room, list different fishing techniques, the first to run out of ideas, drink $ times. %s starts!', 4, 1, '', ''],
    ['Go around the room and list what to do if someone has alcohol poisoning, the first person who can\'t think of anything to say drink $ times. %s starts!', 4, 1, '', ''],
    ['Go around the room, list movies with evil aliens, the first to run out of items drink $ times. %s starts!', 4, 1, '', ''],
    ['"Sports that %s has played competitively or as a hobby", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 2, '', ''],
    ['"lonely hearts apps and websites", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Whatever is more than 100 years old and is still used nowadays", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Comedy duos", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Taxes that the government put on our asses", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Movies with two main characters", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['"Streets in Monopoly", first person to repeat themselves or can\'t think of anything drinks $ times. %s, you start', 4, 1, '', ''],
    ['%s, do us the honor of finishing your drink', 5, 1, '', ''],
    ['The two players with the least full glasses must finish them', 5, 3, '', ''],
    ['Down your drink if you\'re a redhead', 5, 0, '', ''],
    ['Down your drink if you weigh more than 180 pounds', 5, 0, '', ''],
    ['Down your drink %s', 5, 1, '', ''],
    ['Down your drinks %s and %s', 5, 3, '', ''],
    ['Down your drinks %s, %s, and %s', 5, 4, '', ''],
    ['Chug! First person to finish their drink gets to fill up someone\'s glass and make them down it', 5, 0, '', ''],
    ['Around the room, recite the alphabet one letter at a time. If %s finishes his/her drink before the end of the alphabet, everyone has to down their drinks', 5, 1, '', ''],
    ['%s, count to 40 before %s can finish his/her drink. If you fail, you down your drink too', 5, 2, '', ''],
    ['The last person to join in, down your drink!', 5, 0, '', ''],
    ['%s, say the alphabet in reverse, down it for every mistake', 5, 1, '', ''],
    ['%s finish the drink of one of the other players', 5, 1, '', ''],
    ['Rum or whiskey? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Chinese food or Mexican food? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Burgers or hotdogs? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Football or baseball? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Would you rather be able to fly or teleport? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Would you rather watch a documentary on Justin Bieber or drink a spoon of paint? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Would you rather go to a 4-hour opera or drink a shot of salt water? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Would you rather cut off your hand or your foot? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Would you rather eat one cup of dirt or run a marathon? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Would you rather eat a cat or a dog? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Would you rather let 100 unknown people die or one of your friends? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Would you rather win 1 million dollars or be presented a real light saber by Luke Skywalker? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Would you rather speak 9 languages fluently or eat endlessly without becoming fat? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Would you rather have permanent season box seats to NFL games or win the lottery? Everyone vote at the same time, losing group drinks $ times', 14, 1, 'rugby-game', ''],
    ['Those who answered "win the lottery" you drink $ times', 14, 0, '', 'rugby-game'],
    ['Those who answered "be a boss chillin in your box seats" you can give out $ sips!', 14, 0, '', 'rugby-game'],
    ['Those who answered "be a boss chillin in your box seats" you drink $ times', 14, 0, '', 'rugby-game'],
    ['Puke at night or the morning after? Everyone vote at the same time, losing group drinks $ times.', 14, 1, '', ''],
    ['Would you rather have a magic carpet or a refrigerator that fills up automatically? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For or against hair gel? Everyone vote at the same time, losing group drink $ times.', 14, 0, '', ''],
    ['For or against living eternally while earning only $10 a day? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For or against mixed bathrooms? Everyone vote at the same time, losing group drink $ times', 14, 0, '', ''],
    ['For or against watching a knife thrower screwing up live? Everyone vote at the same time, losing group drink $ times', 14, 0, '', ''],
    ['Would you rather live the life of Harry Potter or Bill Gates? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather earn 1,000,000,000 dollars or be able to go through walls? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have a bunch of pasta or a seasonal salad? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather win a new car or a Eurotrip? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be invited to a TV show or a talk show? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have no more internet or no more phone? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have no one attend your wedding or your funeral? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather live in a world without problems or lead it? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather find true love or earn 1,000,000 dollars? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be tattooed or pierced? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be a lawyer or a doctor? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather eat chicken or beef? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Are you more into rock or electro? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Xbox or Playstation? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather win the lottery or live twice as long? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be famous or be the best friend of a famous person? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather know the date or the circumstances of your death? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Yes or no, would you play Russian roulette once in your life for 1,000,000,000 dollars? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have more money or more time? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather live as a black man in the USA in 1800 or an Afghan woman today? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Pepsi or Coke? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Burger King or McDonald\'s? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be Gandalf or Dumbledore? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have a snow day or a rainy day? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather get rid of One Direction or Justin Bieber? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Star Trek or Star Wars? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather go to Heaven right now or live on Earth forever? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather listen to today\'s music or music before the 2000s? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Emma Watson or Natalie Portman? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have an 80s hairstyle or dress like the 80s? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have nightmares every single night or see dead people? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather surf on the web or in the ocean? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For or against having a program to delete your browser history every week? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather work for Facebook or Google? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather play Super Smash Bros Brawl or Mario Kart? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For or against the one-piece swimsuit? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For or against stamp collections? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For or against electric barbecues? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For or against vests? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For or against owning a tractor? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather swap your sex when you want or have a real Squirtle? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather go into the future or the past? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have a rewind button in your life or a pause button? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather make mermaids or unicorns real? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather double your weight or lose half of it? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have infinite knowledge or rule the entire world? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be able to fly or read minds? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be the richest person on earth or be immortal? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be able to speak any language or to animals? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be the sexiest person on earth or the smartest? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather play all instruments perfectally or speak 15 languages? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather watch your dreams on TV or be able to fall asleep with the snap of your fingers? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather live as Hermione Granger or Bella from Twilight? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be stuck in an elevator or on a ski lift? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For or against getting changed into someone else forever? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather never see your reflect in the mirror or never be able to take a shower again? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have no more bad drivers or never wait in line again? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather get stuck on a desert island with no one or the four people you hate most? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have no friends or never be able to use an electric device anymore? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather listen to a movie or watch it without sound? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be considered as someone extremely weird or have no single special thing about you at all? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather play heads or tails with 1,000,000 dollars once or earn 2,000 dollars right now? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather retire right now with 1,000,000 dollars or have your dream job for 3,000 dollars per month? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have an extraordinary house in a common place or the opposite? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be living in a world without mariage or without divorce? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather add a new law of your choice or delete one? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather never have embarassing moments anymore or earn 100 dollars for each one? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather need no sleep at all or be able to move objects with your mind? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather lose an eye or walk on all fours for one year? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather drink fuel or smoke plastic? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For or against farting only Hugo Boss perfume, but at least one hundred times a day? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather pee orange juice or have saliva that tastes like lemon juice? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For a day, would you rather have long hair on your chin or ass hair on your cheeks? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather speak only Japaneese or be wearing only pirate clothes? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather win a world trip or not be able to shit anymore (it vanishes on its own)? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have a foot for your hand or one big eye in the middle of your forehead? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have a tattoo "Silvester S." on your forehead or have your nose on your cheek? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have two cork arms or adopt a blind person? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For or against calling your dog "Barack"? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be able to walk on rainbows or speak to flies? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather change your last name to "Hitler" or never eat chocolate again? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather eat a live cockroach or be bald? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather die having your head stuck in elevator doors or slipping on a banana peel? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather have a Siamese twin or not be able to get closer than 5 meters to anyone? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather adopt a baby penguin or a baby monkey? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be a ninja or a pirate? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be deaf in one ear or not be able to access the internet for more than 1 hour per week? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather control your dreams or be able to record and watch them on TV? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['For the next 5 years, would you rather be tetraplegic or go into a coma? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be disabled and very clever or fit and totally nuts? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather be attacked by a lion or one hundred hysterical cats? Everyone vote at the same time, losing group drinks $ times.', 14, 0, '', ''],
    ['Would you rather look like %s or %s? Vote at the same time, losing group drink $ times', 14, 2, '', ''],
    ['For or against having "TAXES" tatooed on your forehead and never having to pay them again? Vote at the same time, losing group drinks $ times', 14, 0, '', ''],
    ['Would you rather live in another country or move from city to city every 6 months? Vote at the same time, losing group drinks $ times', 14, 0, '', ''],
    ['Would you rather go to prison for 6 months or live alone for a whole year? Vote at the same time, losing group drinks $ times', 14, 0, '', ''],
    ['Amy Schumer or Chelsea Handler? Vote at the same time, losing group drinks $ times', 14, 0, '', ''],
    ['Chris Rock or Adam Sandler? Vote at the same time, losing group drinks $ times', 14, 0, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "give up", "salmon", "Superman" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "tear down", "priest", "the Smurfs" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "damage", "herpes", "Queen of England" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "accost", "a fly", "Barack Obama" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "bark", "mussel", "Donald Trump" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "abuse", "antenna", "Angela Merkel" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "speed up", "suicide", "%s" and "Robin Hood", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "hang out", "Lindsay Lohan", "%s" and "G-string", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "welcome", "jail", "Katniss" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "accuse", "defloration", "Peeta Mellark", and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "strive", "limousine", "%s" and "Effie Trinket", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "activate", "%s", "Neo" and "cheddar", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "flatulate", "%s", "Iron Man" and "camembert", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "admit", "yogurt", "%s" and "Hulk", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "admire", "%s", "Pink Floyd" and "pistachio", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "adopt", "%s", "cod" and "Babar", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "love", "%s", "Eminem" and "ball", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "adress", "%s", "Power Rangers" and "rind", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "ventilate", "cauliflower", "%s" and "Jedi", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "show off", "slipper", "Luke Skywalker" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "kneel", "Tom Jones", "babybel" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "shake", "%s" and "giraffe", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "staple", "sardine", "fetus" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "help", "mom", "scab" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sharpen", "banana" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "like", "ganglion", "%s" and "Maya the Bee", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "alert", "Marge Simpson", "bra" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "marsh", "moon", "wet" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "entertain", "plague", "seam" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "animate", "kidney", "ice cream truck" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "call", "Marilyn Monroe", "%s" and "surimi", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "teach", "volcano", "vomit" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "tear off", "bath", "stomach" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "spray", "shower", "Snow White" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "suck up", "fig", "sand" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "murder", "Santa Clause", "bullfight" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "satisfy", "rummy", "%s" and "sin", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "swallow", "tide", "%s" and "bratwurst", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "admit", "pee", "eyesore" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "bathe", "photocopy", "fart" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "walk along", "burp", "zucchini" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sweep away", "striptease", "enema" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "have a hard on", "%s", "chicken" and "beach", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "ban", "%s", "Sponge Bob" and "jersey", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "baptize", "mayonnaise", "%s" and "cow", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "beat", "casserole", "%s" and "priest", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "chat", "socks", "sperm" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "drool", "song", "condom" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "stutter", "night out", "%s" and "sex toy", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "bless", "%s", "zoo" and "boner", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "cradle", "%s", "Bee Gees" and "ball sack", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "kid", "%s", "blow torch" and "turtle", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "drink", "LSD", "rainbow" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sulk", "hole", "%s" and "sausage", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "starve", "diaper", "rotten" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "boil", "hand", "orgasm" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "Jimmy Fallon", "blowie", "shine" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "odd jobs", "beep", "therapy" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "brush", "tiger", "anal" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "graze", "happy ending", "cotton swab" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "crush", "mangina", "wreckage" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "burn", "chocolate", "sobriety" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "hide", "monkey", "anus" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "caress", "hard-on", "crew" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "break", "ice", "casserole" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "celebrate", "Jesus Christ", "%s" and "porn", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sing", "drug", "Pikachu" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "load", "dick", "Desperate Housewives" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "charm", "police", "%s" and "fisting", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "hunt", "fingering", "%s" and "carrot", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "punish", "dentist", "%s" and "poolside", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "ride", "can", "%s" and "son of a b*tch", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "weep", "chlamydia", "bachelor" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "take a shit", "earwax", "pain" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pick up", "panty liner", "Iron Man" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "circumsize", "fish", "spoon" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "polish", "pyramid", "bum" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "slap", "poison", "%s" and "afterbirth", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "blink", "panini", "Scooby-Dum" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "clone", "chimpanzee", "Caesar" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "nail down", "tooth fairy", "screwdriver" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "bang", "skateboard", "Jennifer Aniston" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "corner", "chicory", "tit" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "collect", "fetus", "%s" and "garden", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "collapse", "sandwich", "Vegeta" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "color", "pillow", "seed" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "command", "pickle", "%s" and "Eiffel tour", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "comment", "gaydar", "%s" and "fight", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "understand", "asparagus", "Don Juan" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "vouch", "tomato", "tub" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "dick-free day", "Flash Gordon", "%s" and "GPS", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "confess", "bald bear", "raging boner" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "freeze", "stranger", "%s" and "puppy", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "ingest", "kiwi", "%s" and "Green Lantern", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "build", "%s", "numb" and "knob", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "suck up", "vampire", "%s" and "lullaby", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "gaze", "foreskin", "Captain America" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "copy", "cooked meats", "exam" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "jerk off", "cucumber", "pedestrian" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "correct", "shithouse", "Catwoman" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sleep with", "popcorn", "Canada" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sew", "Captain Hook", "penis tip" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "whine", "salami", "vice" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "spit", "black hole", "mushroom" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "overcook", "feta", "nipple" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "puff", "eggplant", "Lancelot" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "split", "Quasimodo", "washer" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "copulate", "party", "Pokémon" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "create", "Smurfette", "jizz" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "burst", "sister", "Wolverine" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "yell", "watermelon", "coarse salt" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "criticize", "Justin Bieber", "panties" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "crunch", "Megan Fox", "melon" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "gather", "ass hair", "bust" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "cook", "cornhole", "elderly person" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "cook", "otter", "sperm" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "scrape out", "fungi", "lip" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "dance", "gorilla", "ball" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "unblock", "narcotics", "Emma Watson" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "let down", "anal", "lychee" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "unload", "female ejaculation", "fire" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "rip apart", "cousin", "rectum" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "tear up", "vagina", "drawbridge" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "take off", "park", "asshat" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "fool around", "nose", "Jesus ejaculates" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "beautify", "tail", "jackass" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "cut off", "foot", "chocolate mousse" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "defend", "skankface", "cigarette" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "challenge", "pine tree", "Snoop Dog" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "smash open", "Jacky Chan", "retarded" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "savor", "gluten-free", "narcotics" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "breakfast", "diarrhea", "puddle" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "resign", "raccoon", "gun" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "knock down", "baby", "toilet" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "strip down", "cock cage", "teddy bear" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "lent", "Cancun", "missile" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pluck", "T-rex", "swag" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "deflower", "Michael Jackson", "tortilla" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "undress", "Michael Jordan", "anal plug" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "desire", "trampoline", "autistic" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "draw", "chastity device", "floating island" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "detach", "grandmother", "parachute" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "unscrew", "crumbs", "Ibiza" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "devour", "pumpkin", "Jessica Biel" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "digest", "porn stash", "aunty" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "have dinner", "surf", "urine" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "lead", "squid", "segway" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "entertain", "alcohol", "dolphin" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "divorce", "titty-twister", "belly button" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "finger", "Leonardo Di Caprio", "foot spa" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "dominate", "scissors", "homewrecker" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "give", "blind person", "assgasm" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "dope", "child", "crab" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sleep", "rock star DNA", "blowie" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "hit on", "machine gun", "Scooby Doo" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "straighten", "dried sausage", "conjoined twins" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "trick", "kid", "Moses" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "harden", "retarded", "cow fart" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "overwhelm", "dude", "magic bus" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "spread out", "dead rat", "FaceTime" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "curl up", "ejaculate", "missile" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "escape", "pyramid", "marijuana" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "fail", "Niagara", "piano" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "splash", "sting", "jam" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "brighten", "belt", "synagogue" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "enlighten", "storm", "poppers" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "crush", "dentures", "piss" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "graze", "reptile", "Ben Affleck" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "get lost", "Tuesday", "elephant" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "dry", "Elephant Man", "snake" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "ejaculating", "hippie", "lobster" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "widen", "lie", "sod*my" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "murder", "one hundred kisses", "hat" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "educate", "muse", "baked beans" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pack", "fork", "dragon" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "kiss", "bottom line", "butt" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "hire", "multiple personality", "Paris Hilton" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "bother", "ripper", "spring" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sue", "Michael Douglas", "suppository" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "move in", "adultery", "potty" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "prevent", "gas", "chaotic" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "stink out", "TV", "tummy" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "employ", "sucker", "gross" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "borrow", "eggs", "teenager" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "encourage", "louse", "sex trafficker" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "irritate", "karate", "rated R movie" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "lock up", "pigeon", "naked nap time" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "smoke out", "string", "Jessica Alba" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sew into", "pink flamingo", "Sean Penn" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "light", "lawyer", "boob grab" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "thrust", "cube", "bent chode" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "tell off", "brief boner", "vaseline" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "take off", "node", "green shirt" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "annoy", "brothel", "penis hole" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "investigate", "curtains", "tequila" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "teach", "stab", "potato" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "curse", "waffle", "whale" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "start on", "pancake", "swing" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "burry", "radio", "shanked" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "surrond", "fatty", "prayer" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "train", "french fries", "baby doll" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "come in", "leg", "lip stick" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "wrap", "pharmacy", "weirdo" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "envy", "ice cube", "poop" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "fly away", "gay", "scrotum" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "send", "coffin", "semen" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "spare", "vat", "scumbag" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "amaze", "Brazil", "gang bang" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "spy on", "miscarriage", "rehab" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "wax", "flame thrower", "toilet paper" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "peel", "bride", "sandwich" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "marry", "Halloween", "Jenifer Aniston" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "wear out", "hair stylist", "doctor" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "equip", "seefood diet", "orgasm" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "wipe", "green button", "contraceptive pill" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sneeze", "garden gnome", "rehab" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "stretch", "computer", "virgin prostitute" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "surprise", "penis", "rollercoaster" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "suffocate", "clitoris", "pan" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "strangle", "cupcake", "Welch\'s fruit snack" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "study", "polygamy", "placenta" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "evolve", "shooting star", "cunnilingus" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "excite", "David Bowie", "cheeseburger" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "excuse", "chlorophyll", "Mike Tyson" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "mail", "panda", "jigsaw" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "explore", "crater", "pyromaniac" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "explosion", "stripper", "curling" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "extract", "Beatles", "a boil" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "filter", "ulcer", "nymphomaniac" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "crack", "liver", "urinal" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "float", "testes", "plant" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "smoke", "tourettes", "threesome" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "waste", "cunt", "assclown" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "win", "second hole", "poodle" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "gallop", "anal report", "autocorrect" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "keep", "sweetie", "AIDS" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "park", "trunk", "anal shock" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "waste", "shitaki", "Eddy Murphy" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "spoil", "asian", "anal plug" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "gas", "pencil case", "angry sex" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "freeze", "Zeus", "bastard" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "moan", "bludgeon", "simulation" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "fart around", "trisomy", "soap bubble" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "slide", "splash", "buanus" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "inflate", "coat", "ballcuzzi" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "taste", "tuba", "cuckold" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "grow up", "scooter", "wee-wee" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "scratch", "heater", "pubes" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "engrave", "pubes", "runny shit" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "snack", "pill", "stimulus" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "barbecue", "soybean oil", "big wheel" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "climb", "teat", "tent" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "grumble", "scarf", "finger licking" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "rumble", "disgusting", "baldcore" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "fatten", "vodka", "bald assh*le" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "heal", "gin", "erotic" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "shout", "shot", "baldesac" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "guide", "Harry Potter", "pelican" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "getting used to", "paintball", "Chuck Norris" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "grind", "card game", "cheese" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "haunt", "letter box", "cougar" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pester", "cheek", "ginger" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "host", "10,000", "canadian refrigerator" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "hesitate", "coochie", "jackhammer" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "humiliate", "tremor", "love canal" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "howl", "earthquake", "bear trap" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "ignore", "flower", "rough tongue" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "imitate", "red poppy", "oyster\'s tongue" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "impress", "anaconda", "quicksand" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "print", "khaki", "cyst" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "inform", "bottom smack", "dishwasher" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "flood", "trash", "lavender" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "worry", "diarrhea", "tulip" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "insist", "tightrope walker", "castrate" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "forbid", "tampon", "MacGyver" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "interview", "rhinoplasty", "boogeyman" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "insert", "surgery", "werewolf" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "invent", "noodle", "fermentation" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "invest", "Tajikistan", "butthole fingering" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "irritate", "quiche", "Pakistan" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "throw", "couscous", "bat" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "juggle", "chromosome", "plane" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "play", "monogamy", "finger" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "come", "savanna", "vertical smile" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "swear", "feather duster", "Tasmanian" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "like", "slit a clit", "night club" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "beep", "koala bear", "Antarctic" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "lap", "fox terrier", "shitonium" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "wash", "clood", "fappable" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "lick", "sun", "pornament" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "let it go", "maggot", "air con" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "fire", "podiatrist", "Mexico" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "read", "ninja", "neighborhood" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "deliver", "lemur", "Band-Aid" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "chew", "Bible", "electric bike" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "slim", "nerdjacking", "glory hole" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "eat", "moobs", "facial" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "protest", "baking", "lump" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "miss", "tissue", "metrosexual" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "makeup", "orangutan", "space rocket" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "marry", "bonobo", "pisshap" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "massage", "ant", "USSR" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "watch", "umbrella", "martini" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "mix", "pea-brain", "comet" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "combine", "psychopath", "pustule" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "threat", "mormon", "slug fart" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "suck", "slug", "California" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "meow", "spider", "incest" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "get skinny", "snail", "maracas" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "bet", "hepatitis C", "grasshopper" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "rot", "traffic", "virgin" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "bite", "shitfaced", "Pinocchio" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "blow", "nose", "pervert" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "grind", "vinegar", "London" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "get wet", "lion cub", "prostiboots" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "mold", "ravioli", "ladder" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "whisper", "kangaroo", "kamasutra" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "swim", "forest", "fence" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "to be born", "milky way", "baguette" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "snow", "milk", "Australia" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "clean", "ecstasy", "sewer" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "deny", "strong member", "seal" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "blacken", "willy", "plum" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "feed", "vulva", "wheat" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "drown", "baby mouse", "orange juice" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "obey", "crazy dog", "studio" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "make", "hamster", "printer" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "obsess", "Hiroshima", "hawaiian lei" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "offer", "coelioscopy", "Eminem" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "operate", "suitcase", "Tupac" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "order", "wisdom tooth", "Easter" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "organize", "cotton bud", "Christmas" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "dare", "matchstick", "Lucifer" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "forget", "cock tease", "Mediterranean" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "open", "confessional", "Maghreb" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "paddle", "5 stars", "doll" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pardon", "belly", "toxic waste" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "perfume", "inflatable doll", "taint" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "bet", "tuna", "rabid" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sponsor", "bread", "Uzbekistan" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "share", "semolina", "Bolivia" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "wait", "hair", "Japan" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pay", "hook", "Chile" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "fish", "blacksmith", "finger" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pedal", "sword", "Amsterdam" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "comb", "funky monkey", "rainbow" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "paint", "hot pepper", "goose" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "peel", "toe", "pink" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "hang", "wasp", "bridge" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "penetrate", "hornet", "Yu-Gi-Oh!" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pierce", "warthog", "pipes" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "loose", "ear", "Iceland" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "weight", "dignity", "dungeon" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "fart", "honor", "dungeon" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "photocopy", "courage", "castle" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "photograph", "praline", "fortified castle" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "trap", "ocean", "Rock-paper-scissors" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pilot", "stepmother", "crossbow" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pinch", "palm leaf", "catapult" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "piss", "pliers", "tapeworm" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "delight", "nail", "worm" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "joke", "raisin", "earwig" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "hammer in", "Céline Dion", "baked potato" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "cry", "grasshopper", "bukkake" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "dive", "train", "web" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "polish", "barricade", "glue" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pollute", "protein", "mudguard" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sand", "elementary school", "rattle" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "lay", "doctor", "girly" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "wear", "big guy", "umbilical cord" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "post", "sundial", "meeting" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "push", "lumberjack", "candle" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pratice", "ton", "washing machine" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "take", "tupperware", "young tree" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "prepare", "sailboat", "queen" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "press", "trailer", "contamination" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "lend", "forearm", "rage" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "predict", "vaseline", "oven" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "strut", "zodiac", "airport" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "promise", "underwater earthquake", "oasis" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "prove", "twist", "electrician" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "punish", "toilet", "french fries" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "shorten", "dromedary", "studies" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "hang up", "champagne", "apache" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "purchase", "creaming", "underground" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "tell", "short-sighted", "prism" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "stiffen", "cyclops", "treasure" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "slow down", "bacon fat", "alcoholic" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "complain", "galaxy", "drop of sperm" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "take back", "spicy ice", "funeral" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "soften", "writer", "teabagger" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "tidy", "pope", "sunglasses" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "call back", "collision", "Peru" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "shave", "hippo", "timber" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "reassure", "anvil", "home" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "miss", "toothpaste", "wound" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "bound", "telescope", "booze" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "warm up", "kitten", "microwave" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "recite", "crown", "trumpet" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "reconcile", "bra", "vacuum cleaner" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "reproduce", "human being", "balcony" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "recruit", "medical", "chick" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "step backwards", "bummer", "gym" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "draw up", "faculty", "highway to Hell" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "think hard", "goodbye", "millipede" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "cool down", "alligator", "lights" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "refuse", "sobriety", "ranch" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "delight", "devil", "happy ending" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "adjust", "tag", "bad-ass" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "regret", "Mars", "shark" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "neg", "muzzle", "velociraptor" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "notice", "Shakespeare", "lobotomy" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "repay", "building", "goat" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "rectify", "text-hole", "leopard" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "thank", "orchestra", "handj*b" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "replace", "dream", "radish" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "fill", "salad", "ambulance" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "mix in", "911", "pizza" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "meet", "toenail", "inmate" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "deny", "griddle", "hard-on" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sniff", "will", "can of paint" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "spill", "software", "chips" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "repair", "liquid", "builder" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "lobotomize", "squad", "mosquito net" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "iron", "eyebrow", "breakfast" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "repaint", "magazine", "camp" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "repeat", "poem", "latex" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "answer", "river", "skateboard" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "book", "chess game", "lion" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "resist", "ugly", "claustrophobic" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "resonate", "cave", "pine" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "solve", "immortality", "rabbit" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "respect", "puma", "pyromaniac" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "breath", "chance", "gay pride" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sparkle", "well-being", "slap" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "look like", "leather", "Tori Black" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "squeeze", "ointment", "cradle" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "resurrect", "harpoon", "professor" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "re-establish", "washing", "olive" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "postpone", "cyanide", "aunty" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "hold", "brute", "mosquito" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "fall again", "wheelbarrow", "infant" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "turn over", "rag", "volcano" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "shrink", "sheet music", "anus" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "manage", "black board", "bikini" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "reveal", "corn", "self-service" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "dream", "kneecap", "aspirin" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "meet again", "coat", "virgin" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "laugh", "basketball", "foreskin" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "rinse", "athlete", "creampie" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "laugh", "apple pie", "crab" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "risk", "hourglass", "gelatin" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "snore", "farting lesson", "barbecue" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "purr", "casino", "carrot" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "burp", "humid", "marshmallow" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "roast", "mammoth", "deodorant" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "blush", "cancer", "Bill Clinton" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "gripe", "acne", "armpit" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sacrifice", "emergency", "drawer" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "bleed", "witch", "water polo" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "salt", "mocassin", "volleyball" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "salt", "boar", "jungle" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "mess up", "dive", "lesbian" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "greet", "chocolate chip", "chimney" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sob", "ski", "firework" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "get smashed", "mascot", "Transylvania" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "get dressed", "architect", "nostril" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "satisfy", "lemon", "orifice" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "jump", "postman", "fax" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "save", "handlebars", "apocalypse" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "soap", "Monday", "president" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "love", "Middle Ages", "tickle" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "scan", "jewels", "Caribbean" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "saw", "uncle", "Las Vegas" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "skip", "camping", "work" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "shake", "gums", "control" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "rescue", "briefcase", "spoon" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "charm", "cousin", "Guatemala" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sow", "asteroid", "Albert Einstein" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "signal", "toast", "walkie-talkie" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sip", "Olympic Games", "papaya" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "ski", "savanna", "crutch" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "heal", "bracelet", "ninja" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "ring", "pirate", "luggage" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "go out", "tweezers", "roast" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "weld", "silk", "hieroglyph" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "blow", "bold", "bear" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "wish", "bugle", "deaf" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "ease", "teddy bear", "boner" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "lift", "garden hose", "crustacean" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "suck", "submarine", "plumber" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sweat", "aunt", "Leaning Tower of Pisa" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "suffocate", "pant", "pubic louse" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "surprise", "diagonal", "detective" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "sculpt", "Canary islands", "clown" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "hit", "dragonfly", "softness" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "tease", "gazelle", "swimsuit" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "compress", "adam\'s apple", "boomerang" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "phone", "triplets", "tank" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "stretch out", "canon", "leg" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "test", "bow", "young boy" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "suck from", "rat", "Ricky Martin" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "pull", "dental cavity", "monk" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "titillate", "lozenge", "One Direction" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "lobotomize", "tooth", "Brad Pitt" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "milk", "walrus", "can" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "play with", "nun", "lawn mower" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "urinate", "parish", "Mongolia" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "empty", "bullet", "seafood" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "rape", "sea urchin", "Denzel Washington" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['%s, write a silly post on Facebook with the words/verbs "puke", "museum", "tapas" and "%s", end it with #brikola.be... Or down your drink', 15, 2, '', ''],
    ['De eerste persoon die een volledig wit object vindt mag $ slokken uitdelen. Bonuspunten als je een witte ballenknijper draagt!', 23, 0, '', ''],
    ['%s, stel een vraag aan iedereen samen. Diegene die eerst juist antwoord mag $ slokken uitdelen', 23, 1, '', ''],
    ['%s, neem een slok van je drinken en gorgel een liedje. Diegene die als eerst het liedje raad mag $ slokken uitdelen', 23, 1, '', ''],
    ['Iedereen moet 2 keer drinken en zijn laatste SMS voorlezen. %s begint', 23, 1, '', ''],
    ['Hoeveel kostten jouw schoenen? Zeg het één voor één en druk op het scherm. %s, begint', 23, 1, 'shoes-price', ''],
    ['De persoon met de duurste schoenen drinkt $ keer', 23, 0, '', 'shoes-price'],
    ['De persoon met de goedkoopste schoenen drinkt $ keer', 23, 0, '', 'shoes-price'],
    ['Iedereen met schoenen duurder dan 50 euro drinkt $ keer', 23, 0, '', 'shoes-price'],
    ['%s, zeg een letter. Iedereen voegt een letter toe tot een woord is gemaakt, wie het woord heeft afgemaakt drinkt $ keer', 23, 1, '', ''],
    ['Kop of munt? Zeg allemaal je keuze en druk op het scherm', 23, 1, 'heads-tails', ''],
    ['Munt, drink $ keer', 23, 0, '', 'heads-tails'],
    ['Kop, drink $ keer', 23, 0, '', 'heads-tails'],
    ['Munt, deel $ slokken uit', 23, 0, '', 'heads-tails'],
    ['Kop, deel $ slokken uit', 23, 0, '', 'heads-tails'],
    ['Beslis wie hier de clown is. Vanaf nu wordt diegene grapjas genoemd en hij/zij moet $ keer drinken', 23, 0, 'dumbest-player', ''],
    ['Grapjas, deel $ slokken uit', 23, 0, '', 'dumbest-player'],
    ['Grappig zijn is leuk, drink een keer', 23, 0, '', 'dumbest-player'],
    ['Beslis wie er het best uitziet, die moet nu $ keer drinken', 23, 0, 'beautiful-player', ''],
    ['Hey schoonheid, je mag $ slokken uitdelen', 23, 0, '', 'beautiful-player'],
    ['De koning der lelijkheid heeft gesproken. Onze schoonheid moet nog een slok drinken', 23, 0, '', 'beautiful-player'],
    ['Beslis wie er het slimst is, die moet nu $ keer drinken', 23, 0, 'smart-player', ''],
    ['Omdat alcohol je intelligenter maakt, drink nog een slok!', 23, 0, '', 'smart-player'],
    ['Hey slimbroek, je mag $ slokken uitdelen', 23, 0, '', 'smart-player'],
    ['%s, doe een scene van een film na. De persoon die correct raad van welke film het is mag $ slokken uitdelen', 23, 1, '', ''],
    ['Iedereen mag 2 slokken uitdelen! %s begint', 23, 1, '', ''],
    ['Zwart of wit? Kies allemaal een keuze en druk op het scherm.', 23, 0, 'white-black', ''],
    ['Het juiste antwoord was... grijs! Iedereen verliest en moet $ keer drinken', 23, 0, '', 'white-black'],
    ['Wit! Zwarte team moet $ keer drinken.', 23, 0, '', 'white-black'],
    ['Zwart! Witte team moet $ keer drinken.', 23, 0, '', 'white-black'],
    ['Ik heb nog nooit (wie het al gedaan heeft moet drinken)! %s, begint.', 23, 1, '', ''],
    ['Ik heb ooit (wie het niet heeft gedaan moet drinken)! %s, begint.', 23, 1, '', ''],
    ['Iedereen heeft 2 seconden om een woord te zeggen gerelateerd aan het vorige woord. %s kiest een woord en begint! De verliezer drinkt $ keer.', 23, 1, '', ''],
    ['%s je zegt "Ik ga op reis en ik neem mee...", en begint met een object toe te voegen. Elke persoon herhaalt de objecten en voegt zelf iets toe. Eerste persoon die faalt drinkt $ keer!', 23, 1, '', ''],
    ['%s je zegt "Ooit op een dag...", en voegt maximum 3 woorden toe. Elke speler herhaalt het verhaal en voegt 3 woorden toe. Eerste persoon die faalt drinkt $ keer.', 23, 1, '', ''],
    ['Doe een gebaar nadat je de gebaren van je voorgangers hebt nagedaan. De verliezer drinkt $ keer! %s begint', 23, 1, '', ''],
    ['Je hebt 1 seconde vlak na de vorige persoon om een woord te zeggen dat een correcte zin kan vormen. De verliezer drinkt $ keer, %s begint!', 23, 1, '', ''],
    ['Spel één voor één de achternaam van de persoon rechts van je. Iedereen die faalt drinkt $ keer, %s begint!', 23, 1, '', ''],
    ['Spel één voor één de voornaam van de persoon rechts van je. Iedereen die faalt drinkt $ keer, %s begint!', 23, 1, '', ''],
    ['Zeg één voor één de laatste film die je hebt gezien, diegene met dezelfde film drinken samen $ slokken', 23, 0, '', ''],
    ['Waarheid of doen? Stel één voor één iets voor aan diegene rechts van jouw, $ slokken als iemand weigert, %s begint', 23, 1, '', ''],
    ['Elke speler mag zoveel slokken uitdelen als die voornamen heeft. %s begint', 23, 1, '', ''],
    ['Herhaal één voor één 5 keer "Moeder snijdt zeven scheve sneden brood" in 7 seconden. De winnaars mogen $ slokken uitdelen, verliezers drinken er $. %s starts', 23, 1, '', ''],
    ['%s, kiest een woord. Wie als eerste een (bestaand) liedje kan zingen met dit woord mag $ slokken uitdelen', 23, 1, '', ''],
    ['%s, drinkt $ slokken. De buur rechts van hem 1 minder, tot 0 wordt bereikt', 23, 1, '', ''],
    ['%s, zeg de naam en leeftijd van elke speler. Elke persoon waarbij je het verpest, mag $ slokken uitdelen', 23, 1, '', '']];

    let returning = new Array();
    joink2.forEach(j => {
        if (j[0].includes('Facebook')) {
            rules.push(new Rule([j[0]], j[3] || j[4], "facebook", last[5] || ''));
        } else if (j[0].includes('Would you rather')) {
            rules.push(new Rule([j[0]], j[3] || j[4], "would-you-rather", last[5] || ''));
        } else if (j[3] !== '' || j[4] !== '') {
            //deel van een to-be-continued rule
            let name = j[3] || j[4];
            let matchingRules = returning.filter(r => r.name === name);
            if (matchingRules.length > 1) {
                throw new Error(`matchingRules > 1, type:${type}`);
            } else if (matchingRules.length === 1) {
                // als hij al bestaat, voeg deze slide er aan toe.
                matchingRules[0].slides.push(j[0]);
            } else {
                // anders voegen we hem toe aan returning
                returning.push(new Rule([j[0]], name, "to-be-continued", last[5] || ''));
            }
        } else {
            // normale regel
            rules.push(new Rule([j[0]], j[3] || j[4], "joink2", last[5] || ''));
        }
    });

    //Niet vergeten returning aan de rules toe te voegen, en de gefaalde hun type terugveranderen
    returning.forEach(r => {
        if (r.slides.length === 1) {
            //normale regel
            r.type = 'joink2';
        }
        rules.push(r);
    });

    rules.sort(rule => Math.random() - 0.5);
    return rules;
}
